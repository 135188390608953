export function formatAmountForDisplay(
  amount: number,
  currency: string,
  rates: [] | false
): string {
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  let rate = []
  let converted = amount
  if (rates) {
    rate = rates.filter((r: any, i) => r.currency === currency)
  }
  if (rate.length > 0) {
    converted = amount * rate[0].rate
  }
  return numberFormat.format(Math.ceil(converted))
}

export function formatAmountForPayment(
  amount: number,
  currency: string,
  rates: [] | false
): number {
  let rate = []
  let converted = amount
  if (rates) {
    rate = rates.filter((r: any, i) => r.currency === currency)
  }
  if (rate.length > 0) {
    converted = amount * rate[0].rate
  }
  return Math.ceil(converted)
}

export function formatAmountForStripe(
  amount: number,
  currency: string
): number {
  let numberFormat = new Intl.NumberFormat(['en-US'], {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'symbol',
  })
  const parts = numberFormat.formatToParts(amount)
  let zeroDecimalCurrency: boolean = true
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
  }
  return zeroDecimalCurrency ? amount : Math.ceil(amount * 100)
}
export function formatNumber(num) {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export function calculateMarkup(
  amount: number,
  baseMarkup: number,
  override: number | null
) {
  if (typeof amount === 'undefined') return 0
  let markup = 0
  if (typeof override !== 'undefined' && override !== null) {
    markup = parseInt(String(override)) / 100
  } else if (baseMarkup > 0) {
    markup = baseMarkup / 100
  }
  markup += 1

  return amount * markup
}
