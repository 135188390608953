import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'

import styles from './popup.module.scss'
import { useEffect, useState } from 'react'

const Popup = (props) => {
  const [closing, setClosing] = useState(false)
  const [placement, setPlacement] = useState({})
  const onClose = () => {
    setClosing(true)
    setTimeout(() => {
      props.onClose()
    }, 250)
  }
  const styleOverride = props.hasOwnProperty('style') ? props.style : {}
  const indicator = props.hasOwnProperty('position') ? props.position : 'right'
  useEffect(() => {
    const updatePlacement = () => {
      const box = props.parent.current.getBoundingClientRect()
      if (box.top < 100) {
        onClose()
      }
      setPlacement({
        position: 'fixed',
        top: box.y + box.height,
        left: box.x + box.width / 2,
      })
    }
    if (props.parent) {
      const box = props.parent.current.getBoundingClientRect()
      setPlacement({
        position: 'fixed',
        top: box.y + box.height,
        left: box.x + box.width / 2,
      })
      document.addEventListener('scroll', updatePlacement)
      return () => {
        document.removeEventListener('scroll', updatePlacement)
      }
    }
  }, [props.parent, onClose])
  return (
    <div
      className={`${styles.popupWrapper} ${styles['popup' + indicator]} ${
        closing ? styles.closing : ''
      }`}
      style={{ ...styleOverride, ...placement }}
    >
      <div className={`${styles.popupInner}`}>
        <div className={`${styles.popupHeader}`}>
          {props.header && (
            <h2 className={`${styles.popupHeaderText}`}>{props.header}</h2>
          )}
          <span className={`${styles.popupClose}`} onClick={onClose} />
        </div>
        <div className={`${styles.popupContent}`}>{props.children}</div>
      </div>
    </div>
  )
}
Popup.propTypes = {
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  position: PropTypes.string,
  header: PropTypes.string,
  parent: PropTypes.object,
}
export default Popup
