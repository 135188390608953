import React, { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../lib/context/cart-context'
import { useTranslation } from 'next-i18next'
import { CurrencyContext } from '../../lib/context/CurrencyContext'
import { SettingsContext } from '../../lib/context/SettingsContext'
import styles from '../popups/general.module.scss'
import { formatAmountForDisplay } from '../../utils/stripe-helpers'
import moment from 'moment'
import { getHotel } from '../../lib/api'
import { ROOM_TYPES } from '../../lib/constants'

const BasketHotel = ({ item }) => {
  const { removeProduct } = useContext(CartContext)
  const [hotelId] = useState(item.hotel)
  const [hotel, setHotel] = useState(null)
  const [roomId, setRoomId] = useState(item.room)
  const { i18n } = useTranslation()
  const { currency } = useContext(CurrencyContext)
  const { settings } = useContext(SettingsContext)

  useEffect(() => {
    getHotel(hotelId, i18n.language).then((data) => {
      setHotel(data)
    })
  }, [hotelId])
  return (
    <>
      {hotel && (
        <div className={styles.checkoutBody}>
          {!item.confirmed && !item.postOrder && (
            <div className={styles.checkoutPending}>Booking pending</div>
          )}
          <div className={styles.checkoutItem}>
            {hotel.rooms &&
              hotel.rooms.length > 0 &&
              hotel.rooms
                .filter((r) => r.id === roomId)
                .map((room, i) => {
                  return <img key={i} src={room.gallery[0].url} alt="" />
                })}
            <div>
              <span className={styles.checkoutPrice}>
                {formatAmountForDisplay(
                  Math.ceil(item.itemTotal) *
                    item.quantity *
                    moment(item.checkout).diff(moment(item.checkin), 'days'),
                  currency,
                  settings.exchangeRate
                )}
              </span>
              <h3>{hotel.name}</h3>
              <span>
                {moment(item.checkin).format('DD MMM YYYY')} -{' '}
                {moment(item.checkout).format('DD MMM YYYY')}
              </span>
              <span>
                {item.description} ({ROOM_TYPES[item.type]})
              </span>
              <span>
                {item.guests} guest{item.guests > 1 ? 's' : ''}, {item.quantity}{' '}
                Room{item.quantity > 1 ? 's' : ''}
              </span>
              <span>
                {formatAmountForDisplay(
                  Math.ceil(item.itemTotal) * item.quantity,
                  currency,
                  settings.exchangeRate
                )}
              </span>
              <span
                className={styles.checkoutRemove}
                onClick={() => {
                  removeProduct(item)
                }}
              >
                Remove
              </span>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default BasketHotel
