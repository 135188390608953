import React, { useContext, useEffect, useState } from 'react'
import styles from '../popups/general.module.scss'
import { getPackage } from '../../lib/api'
import { useTranslation } from 'next-i18next'
import { formatAmountForDisplay } from '../../utils/stripe-helpers'
import { CurrencyContext } from '../../lib/context/CurrencyContext'
import { SettingsContext } from '../../lib/context/SettingsContext'
import moment from 'moment'
import { CartContext } from '../../lib/context/cart-context'

const BasketPackage = ({ item }) => {
  const { removeProduct } = useContext(CartContext)
  const [packageId] = useState(item.package)
  const [packageDetails, setPackageDetails] = useState(null)
  const { i18n } = useTranslation()
  const { currency } = useContext(CurrencyContext)
  const { settings } = useContext(SettingsContext)

  useEffect(() => {
    getPackage(packageId, i18n.language).then((data) => {
      setPackageDetails(data)
    })
  }, [packageId])
  return (
    <>
      {packageDetails && (
        <>
          <div className={styles.checkoutBody}>
            {!item.confirmed && !item.postOrder && (
              <div className={styles.checkoutPending}>Booking pending</div>
            )}
            <div className={styles.checkoutItem}>
              <img src={`${packageDetails.featuredImage.url}`} alt="" />
              <div>
                <span className={styles.checkoutPrice}>
                  {formatAmountForDisplay(
                    item.individual
                      ? item.itemTotal * item.guests * item.quantity
                      : Math.ceil(item.itemTotal) * item.quantity,
                    currency,
                    settings.exchangeRate
                  )}
                </span>
                <h3>{item.description}</h3>
                <span>
                  {moment(item.checkin).format('DD MMM YYYY')}
                  {item.checkout && (
                    <>- {moment(item.checkout).format('DD MMM YYYY')}</>
                  )}
                  {packageDetails.nights && (
                    <>
                      {' '}
                      x {packageDetails.nights} night
                      {packageDetails.nights > 1 ? 's' : ''}
                    </>
                  )}
                </span>
                <span>
                  {item.quantity} rooms &amp; {item.guests} people
                </span>
                <span>
                  {formatAmountForDisplay(
                    item.itemTotal,
                    currency,
                    settings.exchangeRate
                  )}
                </span>
                <span
                  className={styles.checkoutRemove}
                  onClick={() => {
                    removeProduct(item)
                  }}
                >
                  Remove
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default BasketPackage
