import PropTypes from 'prop-types'

export default function Container({ className, children }) {
  return (
    <div
      className={`container ${
        typeof className !== 'undefined' ? className : ''
      }`}
    >
      {children}
    </div>
  )
}
Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}
