import React from 'react'
import styles from './general.module.scss'
import PropTypes from 'prop-types'
import Link from 'next/link'
import Button from '../button'
import { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../lib/context/cart-context'
import { formatAmountForDisplay } from '../../utils/stripe-helpers'
import { CurrencyContext } from '../../lib/context/CurrencyContext'
import { i18n } from 'next-i18next'
import ApiContext from '../../utils/api-context'
import { SettingsContext } from '../../lib/context/SettingsContext'
import moment from 'moment'
import BasketPackage from '../basket/package'
import BasketRetreat from '../basket/retreat'
import BasketActivity from '../basket/activity'
import BasketHotel from '../basket/hotel'
import BasketApiHotel from '../basket/api-hotel'

const Checkout = ({ onClose }) => {
  const { cartItems, removeProduct } = useContext(CartContext)
  const { currency } = useContext(CurrencyContext)
  const { settings } = useContext(SettingsContext)
  const { apiUrl, cdnUrl } = useContext(ApiContext)
  const [total, setTotal] = useState(0)
  useEffect(() => {
    let subTotal = 0
    for (const item of cartItems) {
      if (item.confirmed || item.postOrder) {
        if (item.type === 'hotelApi') {
          subTotal += Math.ceil(item.itemTotal)
        } else if (item.hotel) {
          subTotal +=
            Math.ceil(item.itemTotal) *
            item.quantity *
            moment(item.checkout).diff(moment(item.checkin), 'days')
        } else if (item.package) {
          // console.log(item)
          subTotal += item.individual
            ? Math.ceil(item.itemTotal) * item.guests
            : Math.ceil(item.itemTotal)
        } else {
          subTotal += item.individual
            ? item.guests * Math.ceil(item.itemTotal)
            : Math.ceil(item.itemTotal) * item.quantity
        }
      }
    }
    setTotal(subTotal)
  }, [cartItems])
  return (
    <div className={`${styles.checkout}`}>
      {cartItems.length === 0 && (
        <div className={styles.checkoutBody}>
          <div className={styles.checkoutItem}>
            <h4>Your basket is empty</h4>
          </div>
        </div>
      )}
      {cartItems.length > 0 &&
        cartItems.map((item, i) => {
          return (
            <React.Fragment key={i}>
              {item.type === 'package' && <BasketPackage item={item} />}
              {item.type === 'retreat' && <BasketRetreat item={item} />}
              {(item.type === 'experience' || item.type === 'therapist') && (
                <BasketActivity item={item} />
              )}
              {item.type === 'hotelApi' && <BasketApiHotel item={item} />}
              {item.hotel && item.room && <BasketHotel item={item} />}
            </React.Fragment>
          )
        })}
      <div className={styles.checkoutFooter}>
        <span>
          Payable Now{' '}
          <span>
            {formatAmountForDisplay(total, currency, settings.exchangeRate)}
          </span>
        </span>
        <Button
          disabled={total === 0}
          width="100%"
          size="small"
          color="green"
          href={'/checkout'}
        >
          View basket and checkout
        </Button>
      </div>
    </div>
  )
}
Checkout.propTypes = {
  onClose: PropTypes.func,
}
export default Checkout
