import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'
import styles from './general.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector'
import PasswordStrengthBar from 'react-password-strength-bar'
import Button from '../button'
import { useTranslation } from 'next-i18next'
import { gql, useMutation } from '@apollo/client'
import { REGISTER_USER, UPDATE_REGISTRATION } from '../../lib/queries'
import LoginPopup from './login'
import { useModal } from '../../lib/context/modal-context'
import ApiContext from '../../utils/api-context'

const Popup = (props) => {
  const [closing, setClosing] = useState(false)
  const { setModal, modal } = useModal()
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordScore: 0,
    confirmedPassword: false,
    telephone: '',
    address: '',
    city: '',
    country: 'United Kingdom',
    region: '',
    postcode: '',
    newsletter: false,
  })
  const loginRef = useRef(null)
  const [hasRegistered, setHasRegistered] = useState(false)
  const [
    register,
    { error: registerError, data: registrationData },
  ] = useMutation(REGISTER_USER)
  const [updateUser] = useMutation(UPDATE_REGISTRATION)
  const { apiUrl } = useContext(ApiContext)
  useEffect(() => {
    if (typeof registrationData !== 'undefined') {
      updateUser({
        variables: {
          firstname: formData.firstName,
          lastname: formData.lastName,
          address: {
            street: formData.address,
            city: formData.city,
            region: formData.region,
            country: formData.country,
            postcode: formData.postcode,
            telephone: formData.telephone,
          },
          id: registrationData.register.user.id,
          interests: null,
        },
      }).then(() => {
        fetch(`${apiUrl}/messages/welcome`, {
          method: 'POST',
          body: JSON.stringify({
            customer: registrationData.register.user.id,
          }),
        }).then((resp) => setHasRegistered(true))
      })
    } else if (typeof registerError !== 'undefined') {
    }
  }, [registrationData, registerError])
  const passwordLength = 8
  const onLogin = () => {
    setModal({
      title: 'Welcome back!',
      content: (
        <LoginPopup
        // onClose={() => setLoginOpen(false)}
        // onCreateAccount={() => setCreateAccountOpen(true)}
        />
      ),
    })
  }
  const selectCountry = (val) => {
    setFormData({
      ...formData,
      country: val,
    })
  }
  const selectRegion = (val) => {
    setFormData({
      ...formData,
      region: val,
    })
  }
  const changeFirstName = (evt) => {
    setFormData({
      ...formData,
      firstName: evt.target.value,
    })
  }
  const changeLastName = (evt) => {
    setFormData({
      ...formData,
      lastName: evt.target.value,
    })
  }
  const changeEmail = (evt) => {
    setFormData({
      ...formData,
      email: evt.target.value,
    })
  }
  const changeTelephone = (evt) => {
    setFormData({
      ...formData,
      telephone: evt.target.value,
    })
  }
  const changeAddress = (evt) => {
    setFormData({
      ...formData,
      address: evt.target.value,
    })
  }
  const changeCity = (evt) => {
    setFormData({
      ...formData,
      city: evt.target.value,
    })
  }
  const changePostcode = (evt) => {
    setFormData({
      ...formData,
      postcode: evt.target.value,
    })
  }
  const changePromotions = (evt) => {
    setFormData({
      ...formData,
      newsletter: evt.target.checked,
    })
  }
  const changePassword = (evt) => {
    setFormData({
      ...formData,
      password: evt.target.value,
    })
  }
  const confirmPassword = (evt) => {
    setFormData({
      ...formData,
      confirmedPassword: evt.target.value === formData.password,
    })
  }
  const changeScore = (score) => {
    setFormData({
      ...formData,
      passwordScore: score,
    })
  }
  const registerUser = () => {
    loginRef.current.scrollTo({
      top: 0,
    })
    if (formData.confirmedPassword === true && formData.passwordScore > 0) {
      register({
        variables: {
          email: formData.email,
          password: formData.password,
          firstname: formData.firstName,
          lastname: formData.lastName,
        },
      })
        .then((resp) => {})
        .catch((res) => {})
    }
  }
  const styleOverride = props.hasOwnProperty('style') ? props.style : {}
  const indicator = props.hasOwnProperty('position') ? props.position : 'right'
  const { t, i18n } = useTranslation()
  return (
    <>
      {!hasRegistered && (
        <>
          <div className={styles.login} ref={loginRef}>
            {!hasRegistered && (
              <>
                <p>
                  An account is required before you can proceed with your
                  booking. Please fill all the forms below:
                </p>
                <p>
                  Just a few quick details and you&apos;re ready to explore,
                  favourite and book the very best of Feel-Good Mallorca.
                </p>
              </>
            )}
            {registerError && (
              <p className={styles.registerError}>
                There was an error registering your account. The email address
                has already been registered.
              </p>
            )}
            <div className={styles.form}>
              {!hasRegistered && (
                <>
                  <div className={styles.field}>
                    <label>{t('first-name')} *</label>
                    <input
                      type="text"
                      value={formData.firstName}
                      onChange={changeFirstName}
                    />
                  </div>
                  <div className={styles.field}>
                    <label>{t('last-name')} *</label>
                    <input
                      type="text"
                      value={formData.lastName}
                      onChange={changeLastName}
                    />
                  </div>
                  <div className={`${styles.field} ${styles.fieldWide}`}>
                    <label>{t('email-address')} *</label>
                    <input
                      type="email"
                      value={formData.email}
                      onChange={changeEmail}
                    />
                  </div>
                  <div className={styles.field}>
                    <label>{t('password')} *</label>
                    <input
                      type="password"
                      value={formData.password}
                      onChange={changePassword}
                    />
                    <PasswordStrengthBar
                      password={formData.password}
                      scoreWords={[
                        t('weak'),
                        t('weak'),
                        t('okay'),
                        t('good'),
                        t('strong'),
                      ]}
                      shortScoreWord={t('too-short')}
                      className={styles.strengthIndicator}
                      minLength={passwordLength}
                      scoreWordStyle={{ fontSize: '10px', lineHeight: '1' }}
                      onChangeScore={changeScore}
                    />
                  </div>
                  <div className={styles.field}>
                    <label>{t('confirm-password')} *</label>
                    <input type="password" onChange={confirmPassword} />
                    <div
                      className={`${styles.passwordConfirm} ${
                        !formData.confirmedPassword
                          ? styles.noMatch
                          : styles.match
                      }`}
                    >
                      {!formData.confirmedPassword
                        ? t('passwords-not-matched')
                        : t('passwords-match')}
                    </div>
                  </div>
                  <div className={`${styles.field} ${styles.fieldWide}`}>
                    <label>{t('phone-number')}</label>
                    <input
                      type="text"
                      value={formData.telephone}
                      onChange={changeTelephone}
                    />
                  </div>
                  <div className={`${styles.field} ${styles.fieldWide}`}>
                    <label>{t('address')} *</label>
                    <input
                      type="text"
                      value={formData.address}
                      onChange={changeAddress}
                    />
                  </div>
                  <div className={styles.field}>
                    <label>{t('city')} *</label>
                    <input
                      type="text"
                      value={formData.city}
                      onChange={changeCity}
                    />
                  </div>
                  <div className={styles.field}>
                    <label>{t('county')}</label>
                    <div className={styles.select}>
                      <RegionDropdown
                        country={formData.country}
                        value={formData.region}
                        onChange={(val) => selectRegion(val)}
                      />
                    </div>
                  </div>
                  <div className={styles.field}>
                    <label>{t('postcode')} *</label>
                    <input
                      type="text"
                      value={formData.postcode}
                      onChange={changePostcode}
                    />
                  </div>
                  <div className={styles.field}>
                    <label>{t('country')} *</label>
                    <div className={styles.select}>
                      <CountryDropdown
                        value={formData.country}
                        onChange={(val) => selectCountry(val)}
                      />
                    </div>
                  </div>
                  <div className={`${styles.field} ${styles.fieldWide}`}>
                    <div className={styles.checkbox}>
                      <input type="checkbox" onChange={changePromotions} />
                      <div className={styles.icon}>
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                    </div>
                    <label>{t('receive-promotions')}</label>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className={styles.loginFooter}>
            {t('already-have-account')}{' '}
            <span onClick={onLogin}>{t('login')}</span>
            <Button
              width="120px"
              size="small"
              color="green"
              onClick={registerUser}
              disabled={
                !(
                  formData.passwordScore > 0 &&
                  formData.confirmedPassword === true &&
                  formData.email !== '' &&
                  formData.firstName !== '' &&
                  formData.lastName !== '' &&
                  formData.address !== '' &&
                  formData.city !== '' &&
                  formData.postcode !== '' &&
                  formData.country !== ''
                )
              }
            >
              {t('register')}
            </Button>
          </div>
        </>
      )}
      {hasRegistered && (
        <>
          <div className={'has-registered'}>
            <p>
              Hola! Welcome to our community. We&apos;re sure you&apos;ll love
              this island of wellness as much as we do. And we&apos;re here to
              make your time on the island really count.
            </p>
            <p>
              Why not add Wellness Traveller to your bookmarks for easy access?
            </p>
            <p>
              To explore, favourite and book the best of feel-good Mallorca,{' '}
              <span onClick={onLogin}>login now</span>.
            </p>
          </div>
        </>
      )}
    </>
  )
}
Popup.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  style: PropTypes.object,
  position: PropTypes.string,
  noRedirect: PropTypes.bool,
}
export default Popup
