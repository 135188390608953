import config from '../config'
import axios from 'axios'
import { TRAVELGATE_QUERY, TRAVELGATE_QUOTE } from './queries'
import moment from 'moment'

export const fetchWeather = async (apiKey) => {
  const res = await fetch(
    `https://api.openweathermap.org/data/2.5/weather?q=Palma,es&appid=${apiKey}&units=metric`,
    {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
      },
    }
  )

  const json = await res.json()
  return json
}

export const fetchPage = async ({
  page,
  locale,
  params = {},
  headers = {},
}) => {
  if (page !== '') {
    const pageResp = await axios({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API_URL}/${page}`,
      params: {
        locale,
        withRelated: [],
        ...params,
      },
      headers,
    })
    return {
      data: pageResp.data,
      errorCode: pageResp.data.length === 0 ? 404 : pageResp.status,
    }
  }
  return {}
}

export const fetchAuthenticatedPage = async ({
  page,
  locale,
  params = {},
  token,
}) => {
  if (page !== '') {
    const pageResp = await axios({
      method: 'GET',
      url: `${process.env.NEXT_PUBLIC_API_URL}/${page}`,
      params: {
        locale,
        withRelated: [],
        ...params,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return {
      data: pageResp.data,
      errorCode: pageResp.data.length === 0 ? 404 : pageResp.status,
    }
  }
  return {}
}

export const fetchFilterTypes = async ({ locale }) => {
  const resp = await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_API_URL}/homepage/filter-types`,
    params: {
      locale,
    },
  })
  return {
    filterTypes: resp.data,
  }
}
export const fetchFilterCategories = async ({ page, locale }) => {
  const resp = await axios({
    method: 'GET',
    url: `${process.env.NEXT_PUBLIC_API_URL}${page}`,
    params: {
      locale,
    },
  })
  return {
    filterCategories: resp.data,
  }
}

export const oneTimePassword = async (id) => {
  // console.log(id)
  const resp = await fetch(`${config.apiUrl}/messages/otp`, {
    method: 'POST',
    body: JSON.stringify({ id }),
  })
  return await resp.json()
}

export const confirmBooking = async (id, message = '') => {
  const resp = await fetch(`${config.apiUrl}/bookings/confirm`, {
    method: 'POST',
    body: JSON.stringify({ id, message }),
  })
  return await resp.json()
}
export const respondToMessage = async (id, response, otp) => {
  const resp = await fetch(`${config.apiUrl}/messages/respond`, {
    method: 'POST',
    body: JSON.stringify({ id, response, otp }),
  })
  return await resp.json()
}

export const getBooking = async (id, otp) => {
  const resp = await fetch(`${config.apiUrl}/bookings/amend`, {
    method: 'POST',
    body: JSON.stringify({ id, otp }),
  })
  return await resp.json()
}

export const amendBooking = async (id, otp, params) => {
  const resp = await fetch(`${config.apiUrl}/messages/amend`, {
    method: 'POST',
    body: JSON.stringify({ id, otp, params }),
  })
  return await resp.json()
}

export const declineBooking = async (id, otp, params) => {
  const resp = await fetch(`${config.apiUrl}/messages/decline`, {
    method: 'POST',
    body: JSON.stringify({
      id,
      otp,
      params: { cartItems: params.cartItems, message: params.message },
    }),
  })
  return await resp.json()
}

export const getPackage = async (id, locale) => {
  const resp = await fetch(`${config.apiUrl}/packages/${id}?locale=${locale}`, {
    method: 'GET',
  })
  return await resp.json()
}
export const getRetreat = async (id, locale) => {
  const resp = await fetch(`${config.apiUrl}/retreats/${id}?locale=${locale}`, {
    method: 'GET',
  })
  return await resp.json()
}

export const getActivity = async (id, locale) => {
  const resp = await fetch(
    `${config.apiUrl}/activities/${id}?locale=${locale}`,
    {
      method: 'GET',
    }
  )
  return await resp.json()
}
export const getHotel = async (id, locale) => {
  const resp = await fetch(`${config.apiUrl}/hotels/${id}?locale=${locale}`, {
    method: 'GET',
  })
  return await resp.json()
}

export const updateBooking = async (id, otp, params) => {
  const resp = await fetch(`${config.apiUrl}/messages/amend`, {
    method: 'POST',
    body: JSON.stringify({
      id,
      otp,
      params: {
        id: params.id,
        cartItems: params.cartItems,
        price: params.price,
        checkIn: params.checkIn,
        checkOut: params.checkOut,
        type: params.type.toLowerCase(),
        date: params.checkIn,
        time: params.time,
        message: params.message,
        status: params.status,
      },
    }),
  })
  return await resp.json()
}

export const searchStays = async (reqParams) => {
  const hotelsResp = await axios.get(`${config.apiUrl}/hotels?locale=en`)
  const hotelsTmp = hotelsResp.data
  const hotels = []
  // console.log(reqParams)
  for (const hotel of hotelsTmp) {
    hotels.push({
      id: hotel.id,
      hotel_ref: hotel.hotel_ref,
      apiType: hotel.apiType,
      tripadvisor_rating: hotel.tripadvisor_rating,
      slug: hotel.slug,
      tone: hotel.tone,
      vibe: hotel.vibe,
      basePrice: hotel.basePrice,
      favourite: hotel.favourite,
      standfirst: hotel.standfirst,
      cardinalPoint: hotel.cardinalPoint,
      overview: hotel.overview,
      filterTab: hotel.filterTab,
      markup: hotel.markup,
      location: hotel.location,
      images: hotel.images,
      featuredAmenities: hotel.featuredAmenities,
      searchFilterTypes: hotel.searchFilterTypes,
      searchFilterAreas: hotel.searchFilterAreas,
      searchFilterDurations: hotel.searchFilterDurations,
      rooms: hotel.rooms,
      name: hotel.name,
      enquiry: hotel.enquiry,
      adultsOnly: hotel.adultsOnly,
    })
  }
  const roiHotels = hotels
  .filter((hotel) => {
    return (
      hotel.apiType === 'Roibos' &&
      (!hotel.enquiry || hotel.enquiry === false)
    )
  })
  .map((hotel) => {
    return hotel.hotel_ref.toString()
  })
  const hobHotels = hotels
    .filter((hotel) => {
      return (
        hotel.apiType === 'HotelBeds' &&
        (!hotel.enquiry || hotel.enquiry === false)
      )
    })
    .map((hotel) => {
      return hotel.hotel_ref.toString()
    })
  const nonApiHotels = hotels.filter((hotel, i) => {
    return hotel.enquiry
  })
  let tgHotels = []
  let naHotels = []
  if (
    hobHotels.length > 0 &&
    moment(reqParams.checkinDate) > moment().add(1, 'day')
  ) {
    try {
      const tgResponse = await fetch('https://api.travelgatex.com', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${process.env.NEXT_PUBLIC_TRAVELGATE_KEY}`,
        },
        body: JSON.stringify({
          query: TRAVELGATE_QUERY,
          variables: {
            criteriaSearch: {
              checkIn: moment(reqParams.checkinDate).format('YYYY-MM-DD'),
              checkOut: moment(reqParams.checkoutDate).format('YYYY-MM-DD'),
              hotels: hobHotels,
              occupancies: reqParams.guests,
              language: 'en',
              nationality: 'ES',
              currency: 'EUR',
              market: 'ES',
            },
            settings: {
              suppliers: null,
              businessRules: null,
              timeout: 24700,
              context: process.env.NEXT_PUBLIC_TRAVELGATE_CONTEXT,
              client: process.env.NEXT_PUBLIC_TRAVELGATE_CLIENT,
              auditTransactions: true,
              testMode: process.env.NEXT_PUBLIC_TRAVELGATE_MODE === 'test',
            },
            filter: {
              access: {
                includes: [process.env.NEXT_PUBLIC_TRAVELGATE_CODE],
              },
            },
          },
        }),
      })
      const tgResults = await tgResponse.json()
      const tgHotelsUnfiltered = tgResults.data?.hotelX?.search?.options ?? []
      if (tgHotelsUnfiltered.length > 0) {
        for (const option of tgHotelsUnfiltered) {
          tgHotels.push(option)
        }
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  } else {
    tgHotels = [...hobHotels]
  }
  if (
    roiHotels.length > 0 &&
    moment(reqParams.checkinDate) > moment().add(1, 'day')
  ) {
    try {
      const tgResponse = await fetch('https://api.travelgatex.com', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `ApiKey ${process.env.NEXT_PUBLIC_TRAVELGATE_KEY}`,
        },
        body: JSON.stringify({
          query: TRAVELGATE_QUERY,
          variables: {
            criteriaSearch: {
              checkIn: moment(reqParams.checkinDate).format('YYYY-MM-DD'),
              checkOut: moment(reqParams.checkoutDate).format('YYYY-MM-DD'),
              hotels: roiHotels.map((code) => code.toString()),
              occupancies: reqParams.guests,
              language: 'en',
              nationality: 'ES',
              currency: 'EUR',
              market: 'ES',
            },
            settings: {
              suppliers: null,
              businessRules: null,
              timeout: 24700,
              context: process.env.NEXT_PUBLIC_TRAVELGATE_ROIBOS_CONTEXT,
              client: process.env.NEXT_PUBLIC_TRAVELGATE_CLIENT,
              auditTransactions: true,
              testMode: process.env.NEXT_PUBLIC_TRAVELGATE_MODE === 'test',
            },
            filter: {
              access: {
                includes: [process.env.NEXT_PUBLIC_TRAVELGATE_ROIBOS_CODE],
              },
            },
          },
        }),
      })
      const tgResults = await tgResponse.json()
      const tgHotelsUnfiltered = tgResults.data?.hotelX?.search?.options ?? []
      if (tgHotelsUnfiltered.length > 0) {
        for (const option of tgHotelsUnfiltered) {
          tgHotels.push(option)
        }
      }
    } catch (error) {
      console.log('ERROR', error)
    }
  } else {
    tgHotels = [...tgHotels, ...roiHotels]
  }

  if (
    nonApiHotels.length > 0 &&
    moment(reqParams.checkinDate) > moment().add(1, 'day') &&
    reqParams.checkoutDate
  ) {
    const filteredNaHotels = nonApiHotels.filter(
      (hotel) => hotel.rooms && hotel.rooms.length > 0
    )
    for (const hotel of filteredNaHotels) {
      let hasRooms = 0
      for (const room of hotel.rooms) {
        const checkIn = moment(reqParams.checkinDate).format('YYYY-MM-DD')
        const checkOut = moment(reqParams.checkoutDate).format('YYYY-MM-DD')
        for (const rPrice of room.price) {
          const startDate = moment(rPrice.startDate)
          const endDate = moment(rPrice.endDate)
          if (startDate && startDate.isSameOrBefore(checkIn)) {
            if (endDate && endDate.isSameOrAfter(checkOut)) {
              hasRooms++
            } else if (
              rPrice.endDate &&
              endDate.isSameOrAfter(checkIn) &&
              endDate.isSameOrBefore(checkOut)
            ) {
              hasRooms++
            }
          } else if (
            rPrice.startDate &&
            startDate.isSameOrAfter(checkIn) &&
            startDate.isSameOrBefore(checkOut)
          ) {
            hasRooms++
          }
        }
      }
      if (hasRooms > 0) {
        naHotels.push(hotel)
      }
    }
  } else {
    naHotels = nonApiHotels
  }

  return {
    apiHotels: Array.from(
      new Set(
        tgHotels.map((opt, i) => {
          return opt.hotelCode
        })
      )
    ),
    tgHotels,
    nonApiHotels: naHotels,
    allHotels: hotels,
  }
}

export const travelgateRoomApi = async (
  searchCheckInDate,
  searchCheckOutDate,
  searchGuests,
  hotelRef
) => {
  const response = await fetch('https://api.travelgatex.com', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `ApiKey ${process.env.NEXT_PUBLIC_TRAVELGATE_KEY}`,
    },
    body: JSON.stringify({
      query: TRAVELGATE_QUERY,
      variables: {
        criteriaSearch: {
          checkIn:
            typeof searchCheckInDate === 'object'
              ? searchCheckInDate.format('YYYY-MM-DD')
              : moment(searchCheckInDate).format('YYYY-MM-DD'),
          checkOut:
            typeof searchCheckOutDate === 'object'
              ? searchCheckOutDate.format('YYYY-MM-DD')
              : moment(searchCheckOutDate).format('YYYY-MM-DD'),
          hotels: [hotelRef.toString()],
          occupancies: searchGuests,
          language: 'en',
          nationality: 'ES',
          currency: 'EUR',
          market: 'ES',
        },
        settings: {
          suppliers: null,
          businessRules: null,
          timeout: 24700,
          context: hotelRef.match(/[A-Z]/) ? process.env.NEXT_PUBLIC_TRAVELGATE_ROIBOS_CONTEXT : process.env.NEXT_PUBLIC_TRAVELGATE_CONTEXT,
          client: process.env.NEXT_PUBLIC_TRAVELGATE_CLIENT,
          auditTransactions: true,
          testMode: process.env.NEXT_PUBLIC_TRAVELGATE_MODE === 'test',
        },
        filter: {
          access: {
            includes: [hotelRef.match(/[A-Z]/) ?  process.env.NEXT_PUBLIC_TRAVELGATE_ROIBOS_CODE : process.env.NEXT_PUBLIC_TRAVELGATE_CODE],
          },
        },
      },
    }),
  })

  const data = await response.json()
  return data.data.hotelX.hasOwnProperty('search') &&
    data.data.hotelX.search.options
    ? data.data.hotelX.search.options.filter((option, i) => {
        return option.hotelCode === hotelRef.toString()
      })
    : []
}

export const travelgateQuoteApi = async (optionRefId) => {
  const response = await fetch('https://api.travelgatex.com', {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `ApiKey ${process.env.NEXT_PUBLIC_TRAVELGATE_KEY}`,
    },
    body: JSON.stringify({
      query: TRAVELGATE_QUOTE,
      variables: {
        criteria: {
          optionRefId,
        },
        settings: {
          suppliers: null,
          businessRules: null,
          timeout: 24700,
          context: process.env.NEXT_PUBLIC_TRAVELGATE_CONTEXT,
          client: process.env.NEXT_PUBLIC_TRAVELGATE_CLIENT,
          auditTransactions: true,
          testMode: process.env.NEXT_PUBLIC_TRAVELGATE_MODE === 'test',
        },
      },
    }),
  })
  const queryResponse = await response.json()
  return queryResponse.data.hotelX.quote
}

export async function sendConcierge(data) {
  const resp = await fetch(`${config.apiUrl}/concierge-page/send`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return await resp.json()
}
export async function validateConciergeToken(data) {
  const resp = await fetch(`${config.apiUrl}/concierge-packages/validate`, {
    method: 'POST',
    body: JSON.stringify(data),
  })
  return await resp.json()
}
export const cookieConsent = async (id) => {
  const resp = await fetch(`${config.apiUrl}/cookie-consent`, {
    method: 'GET',
  })
  return await resp.json()
}
