export const EXAMPLE_PATH = 'cms-strapi'
export const CMS_NAME = 'Wellness Traveller'
export const CMS_URL = 'https://strapi.io/'
export const HOME_OG_IMAGE_URL =
  'https://og-image.now.sh/Next.js%20Blog%20Example%20with%20**Strapi**.png?theme=light&md=1&fontSize=100px&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Ffront%2Fassets%2Fdesign%2Fnextjs-black-logo.svg&images=https%3A%2F%2Fassets.vercel.com%2Fimage%2Fupload%2Fv1590740734%2Fnextjs%2Fexamples%2Fstrapi-logo.svg'
export const WEATHER_API_KEY = '86539e46a0148d4d3caa1bfa826a0bd9'
export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]
export const VALID_EMAIL_REGEXP = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
)
export const ROOM_TYPES = {
  RO: 'Room Only',
  BB: 'Bed & Breakfast',
  HB: 'Half-Board',
  FB: 'Full-Board',
  AD: 'Bed & Breakfast',
  SA: 'Room Only',
  TI: 'All Inclusive',
  MP: 'Half-Board',
}
export const TRAVELGATE_CODES = {
  HotelBeds: {
    context: 'HOB',
    code: 8973,
  },
  Roibos: {
    context: 'ROI',
    code: 24470,
  },
}