import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery, gql } from '@apollo/client'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faShoppingBag,
  faChevronDown,
} from '@fortawesome/pro-regular-svg-icons'
import { faHeart } from '@fortawesome/pro-regular-svg-icons'
import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import Link from 'next/link'
import Logo from '../svg/LogoFull.svg'
import LogoSmall from '../svg/LogoSmall.svg'
import styles from './alt-header.module.scss'
import Popup from '../popup'
import HeaderSearchBar from '../widgets/header-search-bar'
import LanguageSelect from '../language-select'
import CheckoutPopup from '../popups/checkout'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import LoginMenu from '../account/menu'
import { SettingsContext } from '../../lib/context/SettingsContext'

const AltHeader = (props) => {
  const [mobile, setMobile] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)
  const [checkoutOpen, setCheckoutOpen] = useState(false)
  const { alt } = props
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const { navigation } = useContext(SettingsContext)

  useEffect(() => {
    const isMobile = () => {
      setMobile(window.outerWidth <= 767)
    }
    if (window) {
      isMobile()
      window.addEventListener('resize', isMobile)
    }
    return () => {
      window.removeEventListener('resize', isMobile)
    }
  }, [])

  const SubMenu = ({ title, menuItems }) => {
    const [showSubMenu, setShowSubMenu] = useState(false)
    return (
      <React.Fragment>
        <span
          onClick={() => {
            setShowSubMenu(!showSubMenu)
          }}
        >
          {title} <FontAwesomeIcon icon={faChevronDown} />
        </span>
        {showSubMenu && (
          <Popup
            onClose={() => setShowSubMenu(false)}
            style={{ width: '100%' }}
          >
            <ul className={styles.subMenu}>
              {menuItems.map((item, i) => {
                return (
                  <li key={i}>
                    <Link href={item.url} as={item.url} locale={router.locale}>
                      {item.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </Popup>
        )}
      </React.Fragment>
    )
  }

  const MainNav = () => {
    return (
      <div className={styles.mainMobileNavigation}>
        <div className={styles.topNav}>
          {!mobile && <LanguageSelect />}
          <nav className={styles.secondaryNav}>
            <ul>
              <li>
                <Link href={'/about-us'} as={'/about-us'}>
                  {t('about-us')}
                </Link>
              </li>
              <li>
                <Link href={'/contact-us'} as={'/contact-us'}>
                  {t('contact-us')}
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faHeart} />
                <Link href={'/account/wishlist'} as={'/account/wishlist'}>
                  {t('favourites')}
                </Link>
              </li>
              <li className={styles.login}>
                <LoginMenu alt />
              </li>
              <li
                className={styles.basket}
                onClick={() => setCheckoutOpen(true)}
              >
                <FontAwesomeIcon icon={faShoppingBag} />
                <span>{t('basket')}</span>
                {checkoutOpen && (
                  <Popup
                    header="My basket"
                    onClose={() => setCheckoutOpen(false)}
                    style={{ width: '450px', maxWidth: '90vw', right: '-20px' }}
                  >
                    <CheckoutPopup />
                  </Popup>
                )}
              </li>
            </ul>
          </nav>
        </div>
        <div className={styles.mainMenu}>
          <nav className={styles.menu}>
            <ul>
              {navigation
                .filter((menu) => {
                  return menu.Location === 'Header'
                })[0]
                .menuItems.map((item, i) => {
                  return (
                    <li
                      key={i}
                      className={`menu-item ${
                        '/' + props.active === item.url ? styles.active : ''
                      }`}
                    >
                      {/*{item.subMenu.length > 0 && (*/}
                      {/*  <SubMenu title={item.title} menuItems={item.subMenu} />*/}
                      {/*)}*/}
                      {/*{item.subMenu.length === 0 && (*/}
                      <Link
                        href={item.url}
                        as={item.url}
                        locale={router.locale}
                      >
                        {item.title}
                      </Link>
                      {/*)}*/}
                    </li>
                  )
                })}
            </ul>
          </nav>
        </div>
      </div>
    )
  }

  return (
    <header className={styles.header}>
      <div className={`logo-wrapper ${styles.logoWrapper}`}>
        <Link href={'/'}>
          <a>
            <Logo />
          </a>
        </Link>
      </div>
      <LanguageSelect alt />
      <div className={styles.toggleNav}>
        <button
          className={`${styles.hamburger} ${
            menuOpen ? styles.hamburgerOpen : ''
          }`}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        {menuOpen && (
          <Popup onClose={() => setMenuOpen(false)} style={{ right: '16px' }}>
            <MainNav />
          </Popup>
        )}
      </div>
    </header>
  )
}
export default AltHeader
