import { gql } from '@apollo/client'

export const HOMEPAGE = gql`
  query getHomepage($locale: String) {
    homepage(locale: $locale) {
      seoMetaData {
        title
        keywords
        description
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
      HeadingLine1
      HeadingLine2
      HeroBackground {
        url
        ext
        name
        formats
      }
      VideoBackground
      firstButton {
        text
        url
      }
      secondButton {
        text
        url
      }
      introSection {
        tag
        heading
        content
        base_image {
          url
          ext
          name
          formats
        }
        overlay_image {
          url
          ext
          name
        }
      }
      popularSection {
        tag
        heading
        popular_card1 {
          heading
          content
          background_image {
            url
            ext
            name
          }
          URL
        }
        popular_card2 {
          heading
          content
          background_image {
            url
            ext
            name
          }
          URL
        }
        popular_carousel {
          heading
          content
          button_text
          button_link
          background_image {
            url
            ext
            name
          }
        }
      }
      discoverSection {
        heading
        tag
        experiences_image {
          url
          ext
          name
        }
        destinations_image {
          url
          ext
          name
        }
        experiences_carousel {
          heading
          background_image {
            url
            ext
            name
          }
          button_text
          button_link
        }
        destinations_carousel {
          heading
          sub_heading
          background_image {
            url
            ext
            name
          }
          link
        }
      }
      bespoke_block {
        heading
        tag
        content
        bespoke_links {
          heading
          link_text
          link_url
        }
      }
      featuredHotels {
        hotels {
          name
          hotel_ref
          apiType
          type
          tone
          vibe
          basePrice
          markup
          standfirst
          cardinalPoint
          tripadvisor_rating
          favourite
          slug
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          description {
            text
            language
          }
          images {
            url
            order
            type
            image {
              url
              formats
              alternativeText
            }
          }
          slug
          featuredAmenities {
            amenities {
              code
              friendlyName
              type
              slug
              icon {
                url
              }
              friendlyName
              texts {
                language
                text
              }
            }
          }
        }
      }
      featuredExperiences {
        id
        slug
        name
        duration
        capacity
        basePrice
        markup
        standfirst
        cardinalPoint
        tripadvisor_rating
        favourite
        enquiry
        contact {
          name
          email
          telephone
        }
        slug
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        description {
          text
          language
        }
        images {
          url
          order
          type
          image {
            url
            alternativeText
            formats
          }
        }
      }
      testimonials {
        heading
        intro
        link {
          text
          url
        }
        featured {
          title
          quote
          date
          avatar {
            url
            alternativeText
          }
        }
      }
      filterTypes {
        id
        name
      }
      filterAreas {
        id
        name
      }
      filterDurations {
        id
        name
      }
      blog_articles {
        id
        title
        slug
        standfirst
        published_at
        featuredImage {
          url
        }
        categories {
          name
          slug
        }
        tags {
          id
          slug
          name
        }
      }
      tagSearch {
        heading
        typeText
        whereText
        durationText
        buttonText
      }
    }
  }
`
export const HOTELS_SEARCH = gql`
  query getHotels($locale: String) {
    hotels(locale: $locale) {
      id
      slug
      name
      hotel_ref
      apiType
      enquiry
    }
  }
`
export const HOTELS_QUERY = gql`
  query getHotels($locale: String, $where: JSON, $offset: Int, $limit: Int) {
    hotels(locale: $locale, where: $where, start: $offset, limit: $limit) {
      id
      slug
      name
      hotel_ref
      apiType
      markup
      type
      tone
      vibe
      basePrice
      standfirst
      cardinalPoint
      tripadvisor_rating
      favourite
      rating
      slug
      enquiry
      enquiryType
      enquiryIntro
      offersHeading
      contact {
        name
        email
        telephone
      }
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
      description {
        text
        language
      }
      images {
        url
        order
        type
        image {
          url
          alternativeText
          formats
        }
      }
      headerImage {
        url
      }
      pageTitle {
        smallText
        largeText
      }
      overview
      full_review {
        slug
      }
      tags {
        name
      }
      amenities {
        code
        friendlyName
        type
        slug
        icon {
          url
        }
        texts {
          language
          text
        }
      }
      featuredAmenities {
        amenities {
          code
          friendlyName
          type
          slug
          icon {
            url
          }
          friendlyName
          texts {
            language
            text
          }
        }
      }
      customTab1Title
      customTab1Intro
      customTab1Tag
      customTab1Heading
      customTab1Content {
        __typename
        ... on ComponentHotelTextRight {
          image {
            url
            alternativeText
          }
          imageBackgroundColour
          tag
          heading
          content
        }
        __typename
        ... on ComponentHotelTextLeft {
          image {
            url
            alternativeText
          }
          imageBackgroundColour
          tag
          heading
          content
        }
      }
      customTab2Title
      customTab2Intro
      customTab2Standfirst
      customTab2Tag
      customTab2Image {
        url
      }
      customTab2Heading
      customTab2Content
      customTab2Button {
        text
        url
      }
      facilities {
        name
      }
      treatments {
        name
      }
      additionalInformation {
        heading
        content
      }
      rooms {
        id
        name
        code
        cardDescription
        description
        gallery {
          url
        }
        included {
          text
        }
        price {
          startDate
          endDate
          amount
          roomType
          showFrom
        }
        occupancy
        infants
        beds
        amenities {
          code
          friendlyName
          type
          slug
          icon {
            url
          }
          texts {
            language
            text
          }
        }
      }
      hotels {
        id
        slug
        name
        hotel_ref
        apiType
        markup
        type
        tone
        vibe
        basePrice
        standfirst
        cardinalPoint
        tripadvisor_rating
        favourite
        slug
        overview
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        description {
          text
          language
        }
        images {
          url
          order
          type
          image {
            url
          }
        }
        rooms {
          name
          code
          cardDescription
          description
          gallery {
            url
          }
          included {
            text
          }
        }
      }
      treatmentsCta {
        text
        button {
          text
          url
        }
      }
      roomsSuites {
        headingLine1
        headingLine2
        headerImage {
          url
        }
        intro
      }
      searchFilterTypes {
        id
        name
        search_filter_category {
          id
          name
        }
      }
      searchFilterAreas {
        id
        name
      }
      testimonials {
        title
        quote
        date
        avatar {
          url
          alternativeText
        }
      }
      experiences {
        id
        slug
        name
        duration
        capacity
        basePrice
        markup
        standfirst
        cardinalPoint
        tripadvisor_rating
        favourite
        enquiry
        enquiryType
        overview
        pageTitle {
          smallText
          largeText
        }
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        description {
          text
          language
        }
        images {
          url
          order
          type
          image {
            url
          }
        }
      }
      featuredOffers {
        id
        hotel {
          id
          slug
          name
          hotel_ref
          apiType
          markup
          type
          tone
          vibe
          basePrice
          standfirst
          cardinalPoint
          tripadvisor_rating
          favourite
          slug
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          description {
            text
            language
          }
          images {
            url
            order
            type
            image {
              url
            }
          }
          rooms {
            name
            code
            cardDescription
            description
            gallery {
              url
            }
            included {
              text
            }
          }
        }
        experience {
          id
          slug
          name
          duration
          capacity
          basePrice
          markup
          standfirst
          cardinalPoint
          tripadvisor_rating
          favourite
          enquiry
          enquiryType
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          description {
            text
            language
          }
          images {
            url
            order
            type
            image {
              url
            }
          }
        }
        package {
          id
          name
          slug
          featured
          overview
          cardOverview
          cardTag
          gallery {
            url
          }
          featuredImage {
            url
            alternativeText
          }
          dateFrom
          dateTo
          numGuests
          fromPrice
          enquiryType
          nights
        }
      }
    }
  }
`

export const HOTELS_CARD = gql`
  query getHotels($locale: String, $where: JSON, $offset: Int, $limit: Int) {
    hotels(locale: $locale, where: $where, start: $offset, limit: $limit) {
      id
      slug
      name
      markup
      type
      tone
      vibe
      basePrice
      tripadvisor_rating
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
      images {
        url
        order
        type
        image {
          url
          alternativeText
          formats
        }
      }
      pageTitle {
        smallText
        largeText
      }
      overview
    }
  }
`

export const EXPERIENCES_QUERY = gql`
  query getExperiences($locale: String, $where: JSON) {
    experiences(locale: $locale, where: $where) {
      id
      slug
      name
      duration
      capacity
      basePrice
      markup
      standfirst
      cardinalPoint
      tripadvisor_rating
      favourite
      rating
      enquiry
      enquiryType
      enquiryIntro
      filterTypes {
        id
        name
      }
      filterAreas {
        id
        name
      }
      filterTab {
        id
        name
      }
      slug
      offersHeading
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
      description {
        text
        language
      }
      images {
        url
        order
        type
        image {
          url
          formats
        }
      }
      pageTitle {
        smallText
        largeText
      }
      overview
      tags {
        name
      }
      amenities {
        code
        type
        slug
        icon {
          url
        }
        texts {
          language
          text
        }
      }
      featuredAmenities {
        amenities {
          code
          friendlyName
          type
          slug
          icon {
            url
          }
          friendlyName
          texts {
            language
            text
          }
        }
      }
      facilities {
        name
      }
      treatments {
        name
      }
      activities {
        id
        name
        description
        slug
        enquiryIntro
        included {
          text
        }
        image {
          url
        }
        capacity
        minCapacity
        duration {
          durationLength
          durationText
        }
        times {
          day
          slots {
            startTime
          }
          weekdays
        }
        price {
          adult
          child
        }
        pricePer {
          perPerson
          text
        }
      }
    }
  }
`
export const EXPERIENCE_QUERY = gql`
  query getExperiences($locale: String, $where: JSON) {
    experiences(locale: $locale, where: $where) {
      id
      slug
      name
      duration
      capacity
      basePrice
      markup
      standfirst
      cardinalPoint
      tripadvisor_rating
      favourite
      rating
      enquiry
      enquiryType
      enquiryIntro
      offersHeading
      pricePer {
        perPerson
        text
      }
      contact {
        name
        email
        telephone
      }
      spaHotel {
        id
        slug
      }
      filterTypes {
        id
        name
      }
      filterAreas {
        id
        name
      }
      filterTab {
        id
        name
      }
      slug
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
      description {
        text
        language
      }
      images {
        url
        order
        type
        image {
          url
        }
      }
      headerImage {
        url
      }
      pageTitle {
        smallText
        largeText
      }
      overview
      content
      full_review {
        slug
      }
      tags {
        name
      }
      amenities {
        code
        type
        slug
        icon {
          url
        }
        texts {
          language
          text
        }
      }
      featuredAmenities {
        amenities {
          code
          friendlyName
          type
          slug
          icon {
            url
          }
          friendlyName
          texts {
            language
            text
          }
        }
      }
      activitiesTitle
      customTab1Title
      customTab1Intro
      customTab1Tag
      customTab1Heading
      customTab1Content {
        __typename
        ... on ComponentHotelTextRight {
          image {
            url
            alternativeText
          }
          imageBackgroundColour
          tag
          heading
          content
        }
        __typename
        ... on ComponentHotelTextLeft {
          image {
            url
            alternativeText
          }
          imageBackgroundColour
          tag
          heading
          content
        }
      }
      customTab2Title
      customTab2Intro
      customTab2Standfirst
      customTab2Tag
      customTab2Heading
      customTab2Image {
        url
      }
      facilities {
        name
      }
      treatments {
        name
      }
      activities {
        id
        name
        description
        slug
        enquiryIntro
        included {
          text
        }
        image {
          url
        }
        capacity
        minCapacity
        duration {
          durationLength
          durationText
        }
        times {
          day
          slots {
            startTime
          }
          weekdays
        }
        price {
          adult
          child
        }
        pricePer {
          perPerson
          text
        }
      }
      additionalInformation {
        heading
        content
      }
      testimonials {
        title
        quote
        date
        avatar {
          url
          alternativeText
        }
      }
      experiences {
        id
        slug
        name
        duration
        capacity
        basePrice
        markup
        standfirst
        cardinalPoint
        tripadvisor_rating
        favourite
        enquiry
        enquiryType
        overview
        pageTitle {
          smallText
          largeText
        }
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        description {
          text
          language
        }
        images {
          url
          order
          type
          image {
            url
          }
        }
      }
      featuredOffers {
        id
        hotel {
          id
          slug
          name
          hotel_ref
          apiType
          markup
          type
          tone
          vibe
          basePrice
          standfirst
          cardinalPoint
          tripadvisor_rating
          favourite
          slug
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          description {
            text
            language
          }
          images {
            url
            order
            type
            image {
              url
            }
          }
          rooms {
            name
            code
            cardDescription
            description
            gallery {
              url
            }
            included {
              text
            }
          }
        }
        experience {
          id
          slug
          name
          duration
          capacity
          basePrice
          markup
          standfirst
          cardinalPoint
          tripadvisor_rating
          favourite
          enquiry
          enquiryType
          pageTitle {
            smallText
            largeText
          }
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          description {
            text
            language
          }
          images {
            url
            order
            type
            image {
              url
            }
          }
        }
        package {
          id
          name
          slug
          featured
          overview
          cardOverview
          cardTag
          gallery {
            url
          }
          featuredImage {
            url
            alternativeText
          }
          dateFrom
          dateTo
          numGuests
          fromPrice
          enquiryType
        }
      }
      hotels {
        id
        slug
        name
        hotel_ref
        apiType
        markup
        type
        tone
        vibe
        basePrice
        standfirst
        cardinalPoint
        tripadvisor_rating
        favourite
        slug
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        description {
          text
          language
        }
        images {
          url
          order
          type
          image {
            url
          }
        }
      }
    }
  }
`
export const TRAVELGATE_QUERY = `
  query Query($criteriaSearch: HotelCriteriaSearchInput, $settings: HotelSettingsInput, $filter: FilterInput) {
    hotelX {
      search(
        criteria: $criteriaSearch
        settings: $settings
        filter: $filter
      ) {
        context
        auditData {
          transactions {
            request
            response
            timeStamp
          }
          timeStamp
        }
        requestCriteria{
          checkIn
          checkOut
        }
        warnings{
          code
          type
          description
        }
        options {
          surcharges {
            chargeType
            mandatory
            description
            price {
              currency
              binding
              net
              gross
              exchange {
                currency
                rate
              }
              markups {
                channel
                currency
                binding
                net
                gross
                exchange {
                  currency
                  rate
                }
              }
            }
          }
          accessCode
          supplierCode
          market
          hotelCode
          hotelName
          boardCode
          paymentType
          status
          occupancies {
            id
            paxes {
              age
            }
          }
          rooms {
            occupancyRefId
            code
            description
            refundable
            description
            units
            roomPrice {
              price {
                currency
                binding
                net
                gross
                exchange {
                  currency
                  rate
                }
                markups {
                  channel
                  currency
                  binding
                  net
                  gross
                  exchange {
                    currency
                    rate
                  }
                }
              }
            }
            beds {
              type
              description
              count
              shared
            }
            ratePlans {
              code
              name
              effectiveDate
              expireDate
            }
            promotions {
              code
              name
              supplierCode
              start
              end
            }
            features {
              code
            }
            amenities {
              code
              amenityCodeSupplier
              type
              value
              texts
            }
          }
          price {
            currency
            binding
            net
            gross
            exchange {
              currency
              rate
            }
            markups {
              channel
              currency
              binding
              net
              gross
              exchange {
                currency
                rate
              }
            }
          }
          supplements {
            code
            name
            description
            supplementType
            chargeType
            mandatory
            durationType
            quantity
            unit
            effectiveDate
            expireDate
            resort {
              code
              name
              description
            }
            price {
              currency
              binding
              net
              gross
              exchange {
                currency
                rate
              }
              markups {
                channel
                currency
                binding
                net
                gross
                exchange {
                  currency
                  rate
                }
              }
            }
          }
          rateRules 
          cancelPolicy {
            refundable
            cancelPenalties {
              deadline
              hoursBefore
              penaltyType
              currency
              value
            }
          }
         
          remarks
          token
          id
        }
         errors {
          code
          type
          description
        }
        warnings {
          code
          type
          description
        }
      }
    }
  }
`
export const ABOUT_US_QUERY = gql`
  query getAboutUs($locale: String) {
    aboutUs(locale: $locale) {
      heading
      redBlock
      mainTag
      mainHeading
      mainContent
      seoMetaData {
        title
        keywords
        description
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
      heroImage {
        url
      }
      tiledImage {
        url
      }
      insetBlock {
        heading
        tag
        content
        reverse
        image {
          url
        }
        button {
          text
          url
        }
      }
      section2Heading
      section2content
      section2background {
        url
      }
      button {
        text
        url
      }
      section3 {
        tag
        heading
        imageBlock {
          background {
            url
          }
          heading
          intro
          url
        }
      }
      section4tag
      section4heading
      section4content
      section4image {
        url
      }
      volunteerText
      volunteerButton {
        text
        url
      }
      donateText
      donateButton {
        text
        url
      }
      theTeam {
        heading
        intro
        teamMembers {
          person {
            name
            position
            bio
            photo {
              url
            }
          }
        }
      }
      insetBlock2 {
        heading
        tag
        content
        reverse
        image {
          url
        }
        button {
          text
          url
        }
      }
    }
  }
`
export const LOGIN = gql`
  mutation processLogin($identifier: String!, $password: String!) {
    login(
      input: { identifier: $identifier, password: $password, provider: "local" }
    ) {
      jwt
      user {
        id
        email
        profileImage {
          url
          formats
        }
        firstname
        lastname
        favourites {
          id
          hotel {
            id
          }
          experience {
            id
          }
          package {
            id
          }
          therapist {
            id
          }
        }
        address {
          street
          city
          region
          postcode
          country
          telephone
        }
        interests {
          interest
          mailchimpValue
        }
      }
    }
  }
`
export const REGISTER_USER = gql`
  mutation($email: String!, $password: String!) {
    register(input: { username: $email, email: $email, password: $password }) {
      jwt
      user {
        id
        email
      }
    }
  }
`
export const UPDATE_REGISTRATION = gql`
  mutation UpdateUser(
    $id: ID!
    $firstname: String
    $lastname: String
    $address: editComponentCustomerAddressInput
    $interests: [ID]
  ) {
    updateUser(
      input: {
        data: {
          firstname: $firstname
          lastname: $lastname
          address: $address
          interests: $interests
        }
        where: { id: $id }
      }
    ) {
      user {
        id
        email
        firstname
        lastname
        address {
          street
          telephone
          city
          region
          country
          postcode
        }
        profileImage {
          url
        }
        interests {
          interest
          mailchimpValue
        }
      }
    }
  }
`
export const ADD_TRANSACTION = gql`
  mutation AddTransaction(
    $customer: ID!
    $stripeId: String
    $bookingDate: DateTime
    $datePaid: DateTime
    $status: ENUM_TRANSACTION_STATUS
    $amount: Float
    $discount: Float
  ) {
    createTransaction(
      input: {
        data: {
          customer: $customer
          stripeId: $stripeId
          bookingDate: $bookingDate
          datePaid: $datePaid
          status: $status
          amount: $amount
          discount: $discount
        }
      }
    ) {
      transaction {
        id
      }
    }
  }
`
export const ADD_BOOKING = gql`
  mutation AddBooking(
    $hotel: ID
    $activity: ID
    $checkIn: Date
    $checkOut: Date
    $guests: ComponentBookingBookingGuestInput
    $price: Float
    $currency: String
    $status: ENUM_BOOKING_STATUS
    $transaction: ID
    $name: String
    $quantity: Int
    $package: ID
    $room: ID
    $roomType: String
    $tgContent: JSON
    $tgBookingRef: String
  ) {
    createBooking(
      input: {
        data: {
          name: $name
          hotel: $hotel
          activity: $activity
          checkIn: $checkIn
          checkOut: $checkOut
          bookingGuests: $guests
          price: $price
          currency: $currency
          status: $status
          transaction: $transaction
          quantity: $quantity
          package: $package
          room: $room
          roomType: $roomType
          tgContent: $tgContent
          tgBookingRef: $tgBookingRef
        }
      }
    ) {
      booking {
        id
      }
    }
  }
`
export const SEARCH_TAGS = gql`
  query($locale: String) {
    searchFilterAreas(locale: $locale) {
      id
      name
    }
    searchFilterTypes(locale: $locale) {
      id
      name
      search_filter_category {
        id
        name
      }
    }
    searchFilterDurations(locale: $locale) {
      id
      name
    }
  }
`
export const MESSAGES = gql`
  query getMessages($customer: ID) {
    messages(where: { customer: $customer }, sort: "id:desc") {
      id
      sender
      name
      content
      subject
      recipient
      sent
      actioned
      checkIn
      checkOut
      eventTime
      eventGuests
      dateSent
      actions
      customerContent
      type
      replyTo {
        id
      }
      replies {
        sender
        name
        content
        subject
        recipient
        sent
        actioned
        checkIn
        checkOut
        eventTime
        eventGuests
        type
        dateSent
        actions
        customerContent
      }
      activity {
        id
        name
        experience {
          id
          name
        }
      }
      hotel {
        id
        name
        slug
      }
      activity {
        id
        experience {
          id
          slug
          name
        }
        therapist {
          id
          slug
          name
        }
      }
      package {
        id
        slug
        name
      }
      cartItems {
        confirmed
        description
        itemTotal
        guests
        individual
        quantity
        type
      }
      booking {
        status
        price
        name
        roomType
        transaction {
          status
          datePaid
          amount
          discount
          dateRefunded
        }
      }
    }
  }
`
export const ADD_ACTIVITY_ENQUIRY = gql`
  mutation AddMessage(
    $email: String
    $name: String
    $subject: String
    $recipient: String
    $activity: ID
    $checkIn: Date
    $eventTime: Time
    $customer: ID
    $message: String
    $dateSent: DateTime
    $sent: Boolean
    $replyTo: ID
    $type: ENUM_MESSAGE_TYPE
    $eventGuests: Int
    $cart: [ID]
  ) {
    createMessage(
      input: {
        data: {
          sender: $email
          name: $name
          subject: $subject
          customerContent: $message
          activity: $activity
          checkIn: $checkIn
          eventTime: $eventTime
          customer: $customer
          recipient: $recipient
          dateSent: $dateSent
          sent: $sent
          replyTo: $replyTo
          type: $type
          eventGuests: $eventGuests
          cartItems: $cart
        }
      }
    ) {
      message {
        id
        checkIn
        eventTime
        eventGuests
        customer {
          firstname
          lastname
        }
        activity {
          name
          experience {
            contact {
              name
            }
          }
        }
      }
    }
  }
`
export const UPDATE_AVATAR = gql`
  mutation UpdateUser($id: ID!, $profileImage: ID!) {
    updateUser(
      input: { data: { profileImage: $profileImage }, where: { id: $id } }
    ) {
      user {
        id
        email
        firstname
        lastname
        address {
          street
          telephone
          city
          region
          country
          postcode
        }
        profileImage {
          url
        }
        interests {
          interest
          mailchimpValue
        }
        favourites {
          id
          hotel {
            id
          }
          experience {
            id
          }
          therapist {
            id
          }
          package {
            id
          }
        }
      }
    }
  }
`
export const UPDATE_USER_WISHLIST = gql`
  mutation UpdateUser(
    $id: ID!
    $wishlist: [editComponentCustomerWishlistInput]
  ) {
    updateUser(input: { data: { wishlist: $wishlist }, where: { id: $id } }) {
      user {
        wishlist {
          id
          hotel {
            id
            name
            slug
          }
          experience {
            id
            name
            slug
          }
          package {
            id
            name
            slug
          }
          therapist {
            id
            name
            slug
          }
        }
      }
    }
  }
`
export const ADD_TO_WISHLIST = gql`
  mutation CreateFavourites(
    $user: ID
    $hotelId: ID
    $therapistId: ID
    $experienceId: ID
    $packageId: ID
  ) {
    createFavourite(
      input: {
        data: {
          user: $user
          hotel: $hotelId
          experience: $experienceId
          therapist: $therapistId
          package: $packageId
        }
      }
    ) {
      favourite {
        id
        hotel {
          id
        }
        experience {
          id
        }
        package {
          id
        }
        therapist {
          id
        }
      }
    }
  }
`
export const REMOVE_FROM_WISHLIST = gql`
  mutation DeleteFavourite($id: ID!) {
    deleteFavourite(input: { where: { id: $id } }) {
      favourite {
        id
      }
    }
  }
`
export const UPDATE_PROFILE = gql`
  mutation UpdateUser(
    $id: ID!
    $firstname: String
    $lastname: String
    $address: editComponentCustomerAddressInput
    $email: String
  ) {
    updateUser(
      input: {
        data: {
          firstname: $firstname
          lastname: $lastname
          email: $email
          address: $address
        }
        where: { id: $id }
      }
    ) {
      user {
        id
        email
        firstname
        lastname
        address {
          street
          telephone
          city
          region
          country
          postcode
        }
        profileImage {
          url
        }
        interests {
          interest
          mailchimpValue
        }
        favourites {
          id
          hotel {
            id
          }
          experience {
            id
          }
          therapist {
            id
          }
          package {
            id
          }
        }
      }
    }
  }
`
export const TRANSACTIONS = gql`
  query getTransactions {
    transactions {
      created_at
      bookingDate
      datePaid
      status
      amount
      discount
      customerNotes
      dateRefunded
      bookings {
        id
        checkIn
        checkOut
        price
        currency
        roomId
        status
        name
        hotel {
          name
        }
        activity {
          name
          experience {
            name
          }
        }
      }
    }
  }
`
export const BOOKINGS = gql`
  query getBookings {
    bookings(sort: "checkIn") {
      id
      created_at
      checkIn
      checkOut
      eventTime
      price
      currency
      tgContent
      tgBookingRef
      status
      name
      quantity
      hotel {
        name
        slug
        enquiry
        enquiryIntro
        enquiryType
        images {
          url
          order
          type
          image {
            url
            alternativeText
            formats
          }
        }
        contact {
          email
          web
          telephone
          name
        }
      }
      conciergePackage {
        packageName
        discount
        totalGuests
        checkIn
        checkOut
        image {
          url
          alternativeText
          formats
        }
        packageOptions {
          __typename
          ... on ComponentConciergeOptionsHotel {
            image {
              url
              alternativeText
              formats
            }
            description
            room
            hotelName
            price
            numGuests
            checkIn
            checkOut
            location {
              address
              lat
              lng
              city
              postcode
              state
            }
          }
          __typename
          ... on ComponentConciergeOptionsExperience  {
            image {
              url
              alternativeText
              formats
            }
            numGuests
            checkIn
            activityName
            experienceName
            description
            price
            location {
              address
              lat
              lng
              city
              postcode
              state
            }
          }
          __typename
          ... on ComponentConciergeOptionsPractitioner  {
            image {
              url
              alternativeText
              formats
            }
            numGuests
            checkIn
            therapyName
            practitonerName
            description
            price
            location {
              address
              lat
              lng
              city
              postcode
              state
            }
          }
          __typename
          ... on ComponentConciergeOptionsOtherOptions  {
            image {
              url
              alternativeText
              formats
            }
            heading
            description
            price
            options {
              heading
              dateTime
            }
          }
        }
      }
      activity {
        name
        experience {
          name
          slug
          enquiry
          enquiryIntro
          enquiryType
          contact {
            email
            web
            telephone
            name
          }
        }
        image {
          url
          alternativeText
          formats
        }
        therapist {
          name
          slug
          enquiry
          enquiryIntro
          enquiryType
          contact {
            email
            web
            telephone
            name
          }
        }
      }
      package {
        name
        nights
        enquiryIntro
        enquiryType
        contact {
          email
          web
          telephone
          name
        }
        featuredImage {
          url
          alternativeText
          formats
        }
      }
      retreat {
        name
        nights
        enquiryIntro
        enquiryType
        contact {
          email
          web
          telephone
          name
        }
        featuredImage {
          url
          alternativeText
          formats
        }
      }
      transaction {
        id
        created_at
        bookingDate
        datePaid
        status
        amount
        discount
        customerNotes
        dateRefunded
      }
      bookingGuests {
        total
      }
      room {
        name
      }
      roomType
    }
  }
`
export const BOOKING = gql`
  query getBooking($id: ID!) {
    booking(id: $id) {
      id
      created_at
      checkIn
      checkOut
      eventTime
      price
      currency
      tgContent
      tgBookingRef
      status
      name
      quantity
      roomType
      bookingGuests {
        total
      }
      hotel {
        id
        slug
        name
        hotel_ref
        apiType
        type
        tone
        vibe
        basePrice
        standfirst
        cardinalPoint
        tripadvisor_rating
        favourite
        bookingInformation {
          heading
          content
        }
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        contact {
          email
          web
          telephone
          name
        }
        rooms {
          id
          name
          code
          cardDescription
          description
          gallery {
            url
          }
          included {
            text
          }
        }
      }
      room {
        id
        name
        code
        cardDescription
        description
        gallery {
          url
        }
        included {
          text
        }
      }
      activity {
        name
        description
        experience {
          name
          slug
          bookingInformation {
            heading
            content
          }
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          contact {
            email
            web
            telephone
            name
          }
          headerImage {
            url
            alternativeText
            formats
          }
        }
        therapist {
          name
          slug
          bookingInformation {
            heading
            content
          }
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          contact {
            email
            web
            telephone
            name
          }
          headerImage {
            url
            alternativeText
            formats
          }
        }
      }
      transaction {
        id
        created_at
        bookingDate
        datePaid
        status
        amount
        discount
        customerNotes
        dateRefunded
      }
      package {
        id
        slug
        name
        nights
        bookingInformation {
          heading
          content
        }
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        contact {
          email
          web
          telephone
          name
        }
        packageRooms {
          id
          name
          description
        }
      }
      retreat {
        id
        slug
        name
        nights
        bookingInformation {
          heading
          content
        }
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        contact {
          email
          web
          telephone
          name
        }
        packageRooms {
          id
          name
          description
        }
      }
      conciergePackage {
        packageName
        discount
        totalGuests
        checkIn
        checkOut
        image {
          url
          alternativeText
          formats
        }
        packageOptions {
          __typename
          ... on ComponentConciergeOptionsHotel {
            image {
              url
              alternativeText
              formats
            }
            description
            room
            hotelName
            price
            numGuests
            numRooms
            checkIn
            checkOut
            location {
              address
              lat
              lng
              city
              postcode
              state
            }
            contact {
              telephone
              email
              web
              name
            }
            hotel {
              location {
                address
                lat
                lng
                city
                postcode
                state
              }
              contact {
                email
                web
                telephone
                name
              }
            }
          }
          __typename
          ... on ComponentConciergeOptionsExperience  {
            image {
              url
              alternativeText
              formats
            }
            numGuests
            checkIn
            activityName
            experienceName
            description
            price
            location {
              address
              lat
              lng
              city
              postcode
              state
            }
            contact {
              email
              web
              telephone
              name
            }
            experience {
              location {
                address
                lat
                lng
                city
                postcode
                state
              }
              contact {
                email
                web
                telephone
                name
              }
            }
          }
          __typename
          ... on ComponentConciergeOptionsPractitioner  {
            image {
              url
              alternativeText
              formats
            }
            numGuests
            checkIn
            therapyName
            practitonerName
            description
            price
            location {
              address
              lat
              lng
              city
              postcode
              state
            }
            contact {
              email
              web
              telephone
              name
            }
            therapist {
              location {
                address
                lat
                lng
                city
                postcode
                state
              }
              contact {
                email
                web
                telephone
                name
              }
            }
          }
          __typename
          ... on ComponentConciergeOptionsOtherOptions  {
            image {
              url
              alternativeText
              formats
            }
            heading
            description
            price
            options {
              heading
              dateTime
            }
          }
        }
      }
    }
  }
`
export const WEBSITE_SETTINGS = gql`
  query getWebsiteSettings($locale: String) {
    websiteSetting(locale: $locale) {
      id
      title
      website_url
      main_email
      contact_subjects {
        id
        subject
        email
      }
      baseMarkup
      exchangeRate {
        currency
        rate
        symbol
      }
      enquiryIntro {
        hotelEnquiry
        experienceEnquiry
        packageEnquiry
        therapistEnquiry
      }
    }
    navigationMenus(locale: $locale) {
      Location
      menuItems {
        title
        url
        subMenu {
          title
          url
        }
      }
    }
  }
`
// export const BOOKING_OTP = gql`
//   mutation updateBooking($id: ID!) {
//     updateBooking(input: {
//       data: {
//         otp:
//       }
//     })
//   }
// `
export const ADD_CART = gql`
  mutation AddCart($userId: ID, $amended: DateTime) {
    createCart(input: { data: { user: $userId, dateAmended: $amended } }) {
      cart {
        id
      }
    }
  }
`
export const UPDATE_CART = gql`
  mutation UpdateCart($id: ID!, $amended: DateTime) {
    updateCart(input: { data: { dateAmended: $amended }, where: { id: $id } }) {
      cart {
        id
      }
    }
  }
`
export const FILTER_TABS = gql`
  query($locale: String, $where: JSON) {
    filterTabs(locale: $locale, where: $where) {
      id
      name
      type
    }
  }
`
export const ADD_HOTEL_ENQUIRY = gql`
  mutation AddMessage(
    $email: String
    $name: String
    $subject: String
    $recipient: String
    $hotel: ID
    $checkIn: Date
    $checkOut: Date
    $customer: ID
    $message: String
    $dateSent: DateTime
    $sent: Boolean
    $replyTo: ID
    $type: ENUM_MESSAGE_TYPE
    $guests: Int
    $cart: [ID]
  ) {
    createMessage(
      input: {
        data: {
          sender: $email
          name: $name
          subject: $subject
          customerContent: $message
          hotel: $hotel
          checkIn: $checkIn
          checkOut: $checkOut
          customer: $customer
          recipient: $recipient
          dateSent: $dateSent
          sent: $sent
          replyTo: $replyTo
          type: $type
          eventGuests: $guests
          cartItems: $cart
        }
      }
    ) {
      message {
        id
        checkIn
        checkOut
        eventGuests
        customer {
          firstname
          lastname
        }
        hotel {
          name
        }
      }
    }
  }
`
export const ADD_PACKAGE_ENQUIRY = gql`
  mutation AddMessage(
    $email: String
    $name: String
    $subject: String
    $recipient: String
    $package: ID
    $checkIn: Date
    $customer: ID
    $message: String
    $dateSent: DateTime
    $sent: Boolean
    $replyTo: ID
    $type: ENUM_MESSAGE_TYPE
    $guests: Int
    $cart: [ID]
  ) {
    createMessage(
      input: {
        data: {
          sender: $email
          name: $name
          subject: $subject
          customerContent: $message
          package: $package
          checkIn: $checkIn
          customer: $customer
          recipient: $recipient
          dateSent: $dateSent
          sent: $sent
          replyTo: $replyTo
          type: $type
          eventGuests: $guests
          cartItems: $cart
        }
      }
    ) {
      message {
        id
        checkIn
        checkOut
        eventGuests
        customer {
          firstname
          lastname
        }
        hotel {
          name
        }
      }
    }
  }
`
export const ADD_RETREAT_ENQUIRY = gql`
  mutation AddMessage(
    $email: String
    $name: String
    $subject: String
    $recipient: String
    $retreat: ID
    $checkIn: Date
    $customer: ID
    $message: String
    $dateSent: DateTime
    $sent: Boolean
    $replyTo: ID
    $type: ENUM_MESSAGE_TYPE
    $guests: Int
    $cart: [ID]
  ) {
    createMessage(
      input: {
        data: {
          sender: $email
          name: $name
          subject: $subject
          customerContent: $message
          retreat: $retreat
          checkIn: $checkIn
          customer: $customer
          recipient: $recipient
          dateSent: $dateSent
          sent: $sent
          replyTo: $replyTo
          type: $type
          eventGuests: $guests
          cartItems: $cart
        }
      }
    ) {
      message {
        id
        checkIn
        checkOut
        eventGuests
        customer {
          firstname
          lastname
        }
        hotel {
          name
        }
      }
    }
  }
`
export const PACKAGES_WISHLIST = gql`
  query($locale: String, $where: JSON) {
    packages(locale: $locale, where: $where) {
      id
      name
      slug
      featured
      overview
      cardOverview
      cardTag
      markup
      gallery {
        url
      }
      featuredImage {
        url
        alternativeText
        formats
      }
      dateFrom
      dateTo
      numGuests
      fromPrice
      enquiryType
      offersHeading
      packageRooms {
        id
        name
        description
        features {
          text
        }
        rates {
          startDate
          endDate
          adults
          children
        }
        gallery {
          url
          alternativeText
        }
      }
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
    }
  }
`
export const PACKAGES_LISTING = gql`
  query($locale: String) {
    offersAndPackagesPage(locale: $locale) {
      id
      seoMetaData {
        title
        keywords
        description
        pageTitle
        pageIntro
        heroImage {
          url
          alternativeText
        }
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
      filterCategories {
        category {
          id
          search_filter_types {
            id
            name
            packages {
              id
            }
            search_filter_category {
              id
              name
            }
          }
        }
      }
    }
    packages(locale: $locale) {
      id
      name
      slug
      featured
      overview
      cardOverview
      cardTag
      markup
      gallery {
        url
      }
      featuredImage {
        url
        alternativeText
      }
      dateFrom
      dateTo
      numGuests
      fromPrice
      enquiryType
      offersHeading
      packageRooms {
        id
        name
        description
        features {
          text
        }
        rates {
          startDate
          endDate
          adults
          children
          perPerson
        }
        gallery {
          url
          alternativeText
        }
      }
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
    }
    searchFilterTypes(locale: $locale) {
      id
      name
      search_filter_category {
        id
        name
      }
    }
    searchFilterAreas(locale: $locale) {
      id
      name
    }
    filterTabs(locale: $locale, where: { type: "Package" }) {
      id
      name
      type
    }
    homepage(locale: $locale) {
      filterTypes {
        id
        name
      }
    }
  }
`
export const PACKAGES = gql`
  query getPackages($locale: String, $where: JSON) {
    packages(locale: $locale, where: $where) {
      id
      name
      slug
      featured
      overview
      cardOverview
      cardTag
      offersHeading
      markup
      nights
      seoMetaData {
        title
        keywords
        description
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
      contact {
        name
        email
        telephone
      }
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
      pageTitle {
        smallText
        largeText
      }
      gallery {
        url
      }
      featuredImage {
        url
        alternativeText
      }
      rates {
        startDate
        endDate
        adults
        children
        perPerson
      }
      guests {
        adults
        children
        infants
      }
      numGuests
      fromPrice
      retailPrice
      enquiryType
      enquiryIntro
      dateFrom
      dateTo
      includes {
        id
        gallery {
          url
          alternativeText
        }
        description
        name
        feature {
          text
        }
      }
      packageRooms {
        id
        name
        description
        features {
          text
        }
        rates {
          startDate
          endDate
          adults
          children
          perPerson
        }
        gallery {
          url
          alternativeText
        }
      }
      additionalInformation {
        heading
        content
      }
      activities {
        id
        name
        description
        slug
        included {
          text
        }
        image {
          url
        }
        capacity
        minCapacity
        duration {
          durationLength
          durationText
        }
        times {
          day
          slots {
            startTime
          }
        }
        price {
          adult
          child
        }
        pricePer {
          perPerson
          text
        }
        experience {
          id
          name
          contact {
            email
            name
            telephone
          }
        }
      }
      hotel {
        id
        standfirst
        name
        hotel_ref
        apiType
        type
        tone
        vibe
        basePrice
        markup
        standfirst
        cardinalPoint
        tripadvisor_rating
        favourite
        slug
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        description {
          text
          language
        }
        images {
          url
          order
          type
        }
        slug
        contact {
          email
          name
          telephone
        }
      }
    }
  }
`
export const STAYS_META = gql`
  query getStayPage($locale: String) {
    staysPage(locale: $locale) {
      seoMetaData {
        title
        keywords
        description
        pageTitle
        pageIntro
        heroImage {
          url
          alternativeText
        }
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
      filterCategories {
        category {
          id
          search_filter_types {
            id
            name
            hotels {
              id
            }
            search_filter_category {
              id
              name
            }
          }
        }
      }
      searchTabs {
        searchTab {
          id
          name
          type
        }
      }
    }
    searchFilterTypes(locale: $locale) {
      id
      name
      search_filter_category {
        id
        name
      }
    }
    searchFilterAreas(locale: $locale) {
      id
      name
    }
    filterTabs(locale: $locale, where: { type: "Hotel" }) {
      id
      name
      type
    }
    homepage(locale: $locale) {
      filterTypes {
        id
        name
      }
    }
  }
`
export const EXPERIENCES_META = gql`
  query getExperiencesPage($locale: String) {
    experiencesPage(locale: $locale) {
      seoMetaData {
        title
        keywords
        description
        pageTitle
        pageIntro
        heroImage {
          url
          alternativeText
        }
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
      filterCategories {
        category {
          id
          search_filter_types {
            id
            name
            experiences {
              id
            }
            search_filter_category {
              id
              name
            }
          }
        }
      }
      searchTabs {
        searchTab {
          id
          name
          type
        }
      }
    }
    searchFilterTypes(locale: $locale) {
      id
      name
      search_filter_category {
        id
        name
      }
    }
    searchFilterAreas(locale: $locale) {
      id
      name
    }
    filterTabs(locale: $locale, where: { type_ne: "Hotel" }) {
      id
      name
      type
    }
    homepage(locale: $locale) {
      filterTypes {
        id
        name
      }
    }
  }
`
export const SEARCH_META = gql`
  query getGeneralSearchPage($locale: String) {
    generalSearchPage(locale: $locale) {
      seoMetaData {
        title
        keywords
        description
        pageTitle
        pageIntro
        heroImage {
          url
          alternativeText
        }
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
    }
    searchFilterTypes(locale: $locale) {
      id
      name
      search_filter_category {
        id
        name
      }
    }
    searchFilterAreas(locale: $locale) {
      id
      name
    }
    homepage(locale: $locale) {
      filterTypes {
        id
        name
      }
    }
  }
`
export const STATIC_PAGE = gql`
  query staticPages($locale: String, $where: JSON, $navWhere: JSON) {
    staticPages(locale: $locale, where: $where) {
      title
      content
      layout
      seoMetaData {
        title
        keywords
        description
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
    }
    navigationMenus(locale: $locale, where: $navWhere) {
      menuItems {
        title
        url
        subMenu {
          title
          url
        }
      }
    }
  }
`
export const INSPIRATION_PAGE = gql`
  query getInspirationPage($locale: String) {
    inspirationPage(locale: $locale) {
      seoMetaData {
        title
        keywords
        description
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
      heading_small
      heading_large
      intro
      sub_heading_small
      sub_heading_large
      sub_section_intro
      heading_image {
        alternativeText
        ext
        url
      }
      map_image {
        alternativeText
        ext
        url
      }
      unmissable {
        heading
        tag
        card {
          image {
            alternativeText
            ext
            url
          }
          title
          intro
          button_text
          button_link
        }
      }
      seasons {
        heading
        tag
        season_tab {
          temperature
          icon {
            alternativeText
            ext
            url
          }
          background {
            alternativeText
            ext
            url
          }
          months
          heading
          heading_tag
          intro
          stat_1_num
          stat_1
          stat_2_num
          stat_2
          stat_3_num
          stat_3
        }
      }
    }
  }
`
export const THERAPISTS_META = gql`
  query getTherapistsPage($locale: String) {
    therapistsPage(locale: $locale) {
      seoMeta {
        title
        keywords
        description
        pageTitle
        pageIntro
        heroImage {
          url
          alternativeText
        }
        socialMeta {
          title
          description
          socialNetwork
          image {
            url
            alternativeText
          }
          keywords
        }
      }
      filterCategories {
        category {
          id
          search_filter_types {
            id
            name
            therapists {
              id
            }
            search_filter_category {
              id
              name
            }
          }
        }
      }
    }
    searchFilterTypes(locale: $locale) {
      id
      name
      search_filter_category {
        id
        name
      }
    }
    searchFilterAreas(locale: $locale) {
      id
      name
    }
    filterTabs(locale: $locale, where: { type: "Therapist" }) {
      id
      name
      type
    }
    homepage(locale: $locale) {
      filterTypes {
        id
        name
      }
    }
  }
`
export const THERAPISTS_LISTING = gql`
  query getTherapists($locale: String, $where: JSON) {
    therapists(locale: $locale, where: $where) {
      id
      slug
      name
      duration
      capacity
      basePrice
      markup
      standfirst
      cardinalPoint
      tripadvisor_rating
      favourite
      rating
      enquiry
      enquiryType
      enquiryIntro
      filterTypes {
        id
        name
      }
      filterAreas {
        id
        name
      }
      filterTab {
        id
        name
      }
      slug
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
      images {
        url
        order
        type
        image {
          url
          formats
        }
      }
      pageTitle {
        smallText
        largeText
      }
      tags {
        name
      }
    }
  }
`
export const THERAPISTS_QUERY = gql`
  query getTherapists($locale: String, $where: JSON) {
    therapists(locale: $locale, where: $where) {
      id
      slug
      name
      duration
      capacity
      basePrice
      markup
      standfirst
      cardinalPoint
      tripadvisor_rating
      favourite
      rating
      enquiry
      enquiryType
      enquiryIntro
      pricePer {
        perPerson
        text
      }
      contact {
        name
        email
        telephone
      }
      filterTypes {
        id
        name
      }
      filterAreas {
        id
        name
      }
      filterTab {
        id
        name
      }
      slug
      offersHeading
      location {
        address
        lat
        lng
        city
        postcode
        state
      }
      description {
        text
        language
      }
      images {
        url
        order
        type
        image {
          url
          formats
        }
      }
      headerImage {
        url
      }
      pageTitle {
        smallText
        largeText
      }
      overview
      content
      full_review {
        slug
      }
      tags {
        name
      }
      amenities {
        code
        type
        slug
        icon {
          url
        }
        texts {
          language
          text
        }
      }
      featuredAmenities {
        amenities {
          code
          friendlyName
          type
          slug
          icon {
            url
          }
          friendlyName
          texts {
            language
            text
          }
        }
      }
      activitiesTitle
      customTab1Title
      customTab1Intro
      customTab1Tag
      customTab1Heading
      customTab1Content {
        __typename
        ... on ComponentHotelTextRight {
          image {
            url
            alternativeText
          }
          imageBackgroundColour
          tag
          heading
          content
        }
        __typename
        ... on ComponentHotelTextLeft {
          image {
            url
            alternativeText
          }
          imageBackgroundColour
          tag
          heading
          content
        }
      }
      customTab2Title
      customTab2Intro
      customTab2Standfirst
      customTab2Tag
      customTab2Heading
      customTab2Image {
        url
      }
      facilities {
        name
      }
      treatments {
        name
      }
      activities {
        id
        name
        description
        slug
        enquiryIntro
        included {
          text
        }
        image {
          url
        }
        capacity
        minCapacity
        duration {
          durationLength
          durationText
        }
        times {
          day
          slots {
            startTime
          }
          weekdays
        }
        price {
          adult
          child
        }
        pricePer {
          perPerson
          text
        }
      }
      additionalInformation {
        heading
        content
      }
      testimonials {
        title
        quote
        date
        avatar {
          url
          alternativeText
        }
      }
      experiences {
        id
        slug
        name
        duration
        capacity
        basePrice
        markup
        standfirst
        cardinalPoint
        tripadvisor_rating
        favourite
        enquiry
        enquiryType
        location {
          address
          lat
          lng
          city
          postcode
          state
        }
        description {
          text
          language
        }
        images {
          url
          order
          type
          image {
            url
          }
        }
      }
      featuredOffers {
        id
        hotel {
          id
          slug
          name
          hotel_ref
          apiType
          markup
          type
          tone
          vibe
          basePrice
          standfirst
          cardinalPoint
          tripadvisor_rating
          favourite
          slug
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          description {
            text
            language
          }
          images {
            url
            order
            type
            image {
              url
            }
          }
          rooms {
            name
            code
            cardDescription
            description
            gallery {
              url
            }
            included {
              text
            }
          }
        }
        experience {
          id
          slug
          name
          duration
          capacity
          basePrice
          markup
          standfirst
          cardinalPoint
          tripadvisor_rating
          favourite
          enquiry
          enquiryType
          pageTitle {
            smallText
            largeText
          }
          location {
            address
            lat
            lng
            city
            postcode
            state
          }
          description {
            text
            language
          }
          images {
            url
            order
            type
            image {
              url
            }
          }
        }
        package {
          id
          name
          slug
          featured
          overview
          cardOverview
          cardTag
          gallery {
            url
          }
          featuredImage {
            url
            alternativeText
          }
          dateFrom
          dateTo
          numGuests
          fromPrice
          enquiryType
        }
      }
      hotels {
        slug
      }
    }
  }
`

export const ADD_WISHLIST_HOTEL = gql`
  mutation($user: ID!, $hotel: ID!) {
    createWishlistItem(input: { data: { user: $user, hotel: $hotel } }) {
      wishlistItem {
        id
        hotel {
          id
          name
          slug
        }
      }
    }
  }
`
export const ADD_POSTORDER_ENQUIRY = gql`
  mutation AddMessage(
    $email: String
    $name: String
    $subject: String
    $recipient: String
    $booking: ID
    $message: String
    $dateSent: DateTime
    $sent: Boolean
    $replyTo: ID
    $type: ENUM_MESSAGE_TYPE
    $customer: ID
  ) {
    createMessage(
      input: {
        data: {
          sender: $email
          name: $name
          subject: $subject
          customerContent: $message
          booking: $booking
          customer: $customer
          recipient: $recipient
          dateSent: $dateSent
          sent: $sent
          replyTo: $replyTo
          type: $type
        }
      }
    ) {
      message {
        id
      }
    }
  }
`
export const TRAVELGATE_QUOTE = `
  query Query(
    $criteria: HotelCriteriaQuoteInput!
    $settings: HotelSettingsInput
  ) {
    hotelX {
      quote(criteria: $criteria, settings: $settings) {
        auditData {
          transactions {
            request
            response
            timeStamp
          }
          timeStamp
        }
        optionQuote {
          optionRefId
          status
          price {
            gross
            net
          }
          searchPrice {
            gross
            net
          }
          cancelPolicy {
            refundable
            cancelPenalties {
              deadline
              hoursBefore
              value
            }
          }
          remarks
          surcharges {
            code
            description
            mandatory
            price {
              gross
              net
            }
          }
          cardTypes
          addOns {
            distribution {
              key
              value
            }
          }
          rooms {
            occupancyRefId
            code
            features {
              code
            }
            description
          }
          paymentType
        }
        errors {
          code
          type
          description
        }
        warnings {
          code
          type
          description
        }
      }
    }
  }
`
