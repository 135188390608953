import React, { useEffect, useState } from 'react'
import Footer from './footer'
import Header from './header/index'
import AltHeader from './alt-header'
import PropTypes from 'prop-types'
import { useCookies } from 'react-cookie'
import { gql, useQuery } from '@apollo/client'
import PopupOffers from '../popups/offers'

const USER_ME = gql`
  query Query {
    self {
      id
      email
      firstname
      lastname
      profileImage {
        url
      }
      wishlist {
        hotels {
          id
        }
      }
    }
  }
`
export default function Layout({
  alt = false,
  account = false,
  children,
  active,
  hideHeader = false,
  hideFooter = false,
  flexColumn,
}) {
  const [loading, setLoading] = useState(true)
  const [mobile, setMobile] = useState(true)
  const [cookies, removeCookie, setCookie] = useCookies(['searchparams'])

  // const { loading, error, data } = useQuery(USER_ME)
  useEffect(() => {
    const isMobile = () => {
      return window.outerWidth < 768 || window.outerHeight < 640
    }
    if (typeof window !== 'undefined') {
      setLoading(false)
      setMobile(isMobile())
      window.addEventListener('resize', isMobile)
    }
    return () => {
      window.removeEventListener('resize', isMobile)
    }
  }, [])
  useEffect(() => {
    if (!cookies.hasOwnProperty('jwt')) {
      if (window.localStorage) {
        window.localStorage.removeItem('user')
      }
    }
  }, [window])
  return (
    <>
      {!loading && (
        <React.Fragment>
          {!alt && !hideHeader && <Header active={active} />}
          {alt && !hideHeader && <AltHeader />}
          <main
            className={`wrapper ${hideHeader ? 'no-header' : ''}${
              flexColumn ? 'flex-column' : ''
            }`}
          >
            {children}
          </main>
          {!hideFooter && <Footer />}
          {process.env.NEXT_PUBLIC_SITE_URL !==
            'https://wellnesstraveller.co' && (
            <span
              className={'fixed z-50 left-0 top-0 bg-red text-white block p-4'}
            >
              STAGING
            </span>
          )}
          <PopupOffers />
        </React.Fragment>
      )}
    </>
  )
}
Layout.propTypes = {
  alt: PropTypes.bool,
  account: PropTypes.bool,
  children: PropTypes.node.isRequired,
  active: PropTypes.string,
  flexColumn: PropTypes.bool,
}
