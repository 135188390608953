import PropTypes from 'prop-types'
import React, { useContext, useEffect, useState } from 'react'
import styles from './general.module.scss'
import Button from '../button'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'
import { gql, useMutation } from '@apollo/client'
import jwtDecode from 'jwt-decode'
import config from '../../config'
import { UserContext } from '../../lib/context/UserContext'
import { ADD_CART, LOGIN } from '../../lib/queries'
import { CartContext } from '../../lib/context/cart-context'
import { useModal } from '../../lib/context/modal-context'
import CreateAccountPopup from './create-account'
import ApiContext from '../../utils/api-context'
import axios from 'axios'

const Popup = (props) => {
  const [forgot, setForgot] = useState(false)
  const [resetPassword, setResetPassword] = useState(false)
  const [error, setError] = useState(false)
  const [closing, setClosing] = useState(false)
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const [cookies, setCookie, removeCookie] = useCookies(['jwt'])
  const { user, setCurrentUser } = useContext(UserContext)
  const { itemCount, id: cartId, createCart, cartItems } = useContext(
    CartContext
  )
  const [processLogin, { data: loginData, error: loginError }] = useMutation(
    LOGIN
  )
  const router = useRouter()
  const { setModal, modal } = useModal()
  const { apiUrl } = useContext(ApiContext)

  useEffect(() => {
    if (typeof loginData !== 'undefined') {
      if (loginData.login.jwt) {
        setError(false)
        const jwtObj = jwtDecode<any>(loginData.login.jwt)
        const exp = jwtObj.hasOwnProperty('exp') ? jwtObj.exp * 1000 : 0
        setCookie('jwt', loginData.login.jwt, {
          ...config.cookieOptions,
          expires: new Date(exp),
        })
        setCurrentUser(loginData.login.user, exp, config.cookieOptions)
        // if (cartId > 0) {
        //   assignCartToUser(loginData.login.user.id, cartId)
        // }
      }
      if (props.noRedirect === false) {
        router.push('/account')
      } else if (props.reload) {
        window.location.reload()
      } else {
        onClose()
        if (typeof props.onClose !== 'undefined') {
          // console.log('Triggering the modal')
          props.onClose()
        }
      }
    }
    if (typeof loginError !== 'undefined') {
      setError(true)
    }
  }, [loginData, loginError])
  const onCreateAccount = () => {
    setModal({
      title: 'Your Account',
      content: <CreateAccountPopup onClose={onClose} />,
    })
  }
  const onClose = () => {
    setModal()
  }
  const doLogin = () => {
    processLogin({
      variables: {
        identifier: formData.email,
        password: formData.password,
      },
    }).catch((resp) => {})
  }
  const changePassword = (evt) => {
    setFormData({
      ...formData,
      password: evt.target.value,
    })
  }
  const changeEmail = (evt) => {
    setFormData({
      ...formData,
      email: evt.target.value,
    })
  }
  const toggleForgot = () => {
    setForgot(!forgot)
  }
  const doForgot = async () => {
    if (formData.email !== '') {
      axios
        .post(`${apiUrl}/auth/forgot-password`, {
          email: formData.email,
          url: `${process.env.NEXT_PUBLIC_SITE_URL}/reset-password`,
        })
        .then((response) => {
          setResetPassword(true)
        })
        .catch((error) => {
          // Handle error.
          console.log('An error occurred:', error.response)
        })
    }
  }
  const styleOverride = props.hasOwnProperty('style') ? props.style : {}
  const indicator = props.hasOwnProperty('position') ? props.position : 'right'
  const { t, i18n } = useTranslation()
  return (
    <>
      <div className={`${styles.login}`}>
        {error && !forgot && (
          <h3 className={styles.registerError}>
            Sorry the credentials you entered are invalid.
          </h3>
        )}
        {!forgot && (
          <>
            <p>Login to your Wellness Traveller account:</p>
            <div className={styles.form}>
              <div className={styles.field}>
                <label>Email Address</label>
                <input
                  type="text"
                  value={formData.email}
                  onChange={changeEmail}
                />
              </div>
              <div className={styles.field}>
                <label>{t('password')}</label>
                <input
                  type="password"
                  value={formData.password}
                  onChange={changePassword}
                />
              </div>
            </div>
            <div className={'forgotten-password'}>
              <p className="mb-0">
                Forgotten your password?{' '}
                <span
                  onClick={toggleForgot}
                  className="cursor-pointer font-semibold text-teal-200"
                >
                  Click here
                </span>
                .
              </p>
            </div>
          </>
        )}
        {forgot && (
          <>
            {!resetPassword && (
              <>
                <p>
                  Please enter your email address in the box below and click the{' '}
                  <strong>Continue</strong> button below:
                </p>
                <div className={styles.form}>
                  <div className={styles.field}>
                    <label>Email Address</label>
                    <input
                      type="text"
                      value={formData.email}
                      onChange={changeEmail}
                    />
                  </div>
                </div>
              </>
            )}
            {resetPassword && (
              <p>
                If your email address is registered with Wellness Traveller, we
                will send the reset password link to you. You should receive the
                email within the next few minutes.
              </p>
            )}
          </>
        )}
      </div>
      <div className={styles.loginFooter}>
        {!props.noReg && (
          <>
            Don&apos;t have an account yet? Let&apos;s get you signed up.
            <span onClick={onCreateAccount}>Create account.</span>
          </>
        )}
        {!forgot && (
          <Button width="120px" size="small" color="green" onClick={doLogin}>
            {t('login')}
          </Button>
        )}
        {forgot && !resetPassword && (
          <Button width="120px" size="small" color="green" onClick={doForgot}>
            Continue
          </Button>
        )}
      </div>
    </>
  )
}
Popup.propTypes = {
  onCreateAccount: PropTypes.func,
  onClose: PropTypes.func,
  style: PropTypes.object,
  position: PropTypes.string,
  noRedirect: PropTypes.bool,
  reload: PropTypes.bool,
  noReg: PropTypes.bool,
}
export default Popup
