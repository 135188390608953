import React, { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../lib/context/cart-context'
import { useTranslation } from 'next-i18next'
import { CurrencyContext } from '../../lib/context/CurrencyContext'
import { SettingsContext } from '../../lib/context/SettingsContext'
import { getActivity } from '../../lib/api'
import styles from '../popups/general.module.scss'
import { formatAmountForDisplay } from '../../utils/stripe-helpers'
import moment from 'moment/moment'

const BasketActivity = ({ item }) => {
  // console.log(item)
  const { removeProduct } = useContext(CartContext)
  const [activityId] = useState(item.activity)
  const [activity, setActivity] = useState(null)
  const { i18n } = useTranslation()
  const { currency } = useContext(CurrencyContext)
  const { settings } = useContext(SettingsContext)
  useEffect(() => {
    getActivity(activityId, i18n.language).then((data) => {
      setActivity(data)
    })
  }, [activityId])
  return (
    <>
      {activity && (
        <>
          <div className={styles.checkoutBody}>
            {!item.confirmed && !item.postOrder && (
              <div className={styles.checkoutPending}>Booking pending</div>
            )}
            <div className={styles.checkoutItem}>
              <img src={`${activity.image.url}`} alt="" />
              <div>
                <span className={styles.checkoutPrice}>
                  {formatAmountForDisplay(
                    item.individual
                      ? item.itemTotal * item.guests
                      : item.itemTotal,
                    currency,
                    settings.exchangeRate
                  )}
                </span>
                <h3>{item.description}</h3>
                <span>
                  {moment(item.checkin).format('DD MMM YYYY')}
                  {item.time !== '00:00:00' && ` ${item.time}`}
                </span>
                <span>{item.guests} people</span>
                <span>
                  {formatAmountForDisplay(
                    item.itemTotal,
                    currency,
                    settings.exchangeRate
                  )}
                </span>
                <span
                  className={styles.checkoutRemove}
                  onClick={() => {
                    removeProduct(item)
                  }}
                >
                  Remove
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}
export default BasketActivity
