import { useEffect, useState } from "react"
import Image from "next/image"
import { useModal } from "../../lib/context/modal-context"
import axios from "axios"
import { object } from "prop-types"
import { useTranslation } from "next-i18next"
import moment from "moment"
import { useCookies } from "react-cookie"
import config from '../../config'
import Newsletter from "../newsletter"
import CreateAccountPopup from "./create-account"
import Button from "../button"

interface PopupContent {
    showAfter: number
    title: string
    content: string
    showNewsletter?: boolean
    showRegistration?: boolean
    image: {
        formats: {
            medium: {
                url
            }
        }
        alternativeText: string
    }
    cta?: {
        text: string
        url: string
    }
}
const PopupOffers = () => {
    const [ popupContent, setPopupContent ] = useState<null | PopupContent>(null)
    const { modal, setModal } = useModal()
    const { i18n } = useTranslation()
    const [cookies, setCookie, removeCookie] = useCookies([])
    useEffect(() => {
        axios({
            method: 'GET',
            url: `${process.env.NEXT_PUBLIC_API_URL}/popups`,
            params: {
                locale: i18n.language
            }
          }).then((r) => {
            if (r.data.length > 0) {
                for (const popup of r.data) {
                    // if (moment(popup.dateFrom).isSameOrBefore( moment() ) && moment(popup.dateTo).isSameOrAfter(moment())) {
                    if ((!popup.dateTo || (moment(popup.dateFrom).isSameOrBefore( moment() ) && moment(popup.dateTo).isSameOrAfter(moment()))) && !cookies[`_popup-${popup.id}`]) {
                        const currentTime = new Date().getTime()
                        setCookie(`_popup-${popup.id}`, 'true', {
                            ...config.cookieOptions,
                            expires: new Date(currentTime + popup.cookieExpires * 24 * 60 * 60 * 1000),
                        })
                        setPopupContent(popup)
                        break;
                    }
                }
            }
          })
    }, [])
    const showLoginBox = () => {
        setModal({
          title: 'Welcome back!',
          content: (
            <CreateAccountPopup
              onClose={() => setModal()}
            />
          ),
        })
      }
    useEffect(() => {
        if (popupContent) {
            setModal({
                delay: popupContent.showAfter,
                title: popupContent.title,
                content: (
                    <>
                        <div className="px-[15px] md:px-[40px] pt-[15px] md:pt-[40px]">
                            {popupContent.image && (
                                <div className="popup-image h-[150px] md:h-[250px] relative w-full">
                                    <Image src={popupContent.image.formats.medium.url} alt={popupContent.image.alternativeText} layout="fill" width={0} height={0} />
                                </div>
                            )}
                            <div dangerouslySetInnerHTML={{ __html: popupContent.content }} />
                            {popupContent.showRegistration && (
                                <div className="mb-8 text-center">
                                    <Button onClick={showLoginBox} width={'150'}>Register</Button>
                                </div>
                            )}
                            {popupContent.cta && (
                                <div className="mb-8 text-center">
                                    <Button href={popupContent.cta.url} width={'fit-content'}>{popupContent.cta.text}</Button>
                                </div>
                            )}
                        </div>
                        {popupContent.showNewsletter && <Newsletter small centered />}

                    </>
                  
                ),
              })
        }
    }, [ popupContent ])
    return <></>
}
export default PopupOffers