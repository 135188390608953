import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/pro-regular-svg-icons'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import Link from 'next/link'
import Logo from '../../svg/LogoFull.svg'
import Popup from '../../popup'
import LanguageSelect from '../../language-select'
import { faChevronDown } from '@fortawesome/pro-light-svg-icons'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import LoginMenu from '../../account/menu'
import Cart from './cart'
import { SettingsContext } from '../../../lib/context/SettingsContext'

const Header = (props) => {
  const [mobile, setMobile] = useState(true)
  const [menuOpen, setMenuOpen] = useState(false)
  const { navigation } = useContext(SettingsContext)
  const { alt } = props
  const { t, i18n } = useTranslation('common')
  const router = useRouter()

  useEffect(() => {
    const isMobile = () => {
      setMobile(window.outerWidth <= 981)
    }
    if (window) {
      isMobile()
      window.addEventListener('resize', isMobile)
    }
    return () => {
      window.removeEventListener('resize', isMobile)
    }
  }, [])

  const SubMenu = ({ title, menuItems }) => {
    const [showSubMenu, setShowSubMenu] = useState(false)
    return (
      <React.Fragment>
        <span
          onClick={() => {
            setShowSubMenu(!showSubMenu)
          }}
        >
          {title} <FontAwesomeIcon icon={faChevronDown} />
        </span>
        {showSubMenu && (
          <Popup
            onClose={() => setShowSubMenu(false)}
            style={{ width: '100%' }}
          >
            <ul className={'subMenu'}>
              {menuItems.map((item, i) => {
                return (
                  <li key={i}>
                    <Link href={item.url} as={item.url} locale={router.locale}>
                      {item.title}
                    </Link>
                  </li>
                )
              })}
            </ul>
          </Popup>
        )}
      </React.Fragment>
    )
  }

  const MainNav = () => {
    return (
      <div className={`${mobile ? 'mainMobileNavigation' : 'mainNavigation'}`}>
        <div className={'topNav'}>
          {!mobile && <LanguageSelect />}
          <nav className={'secondaryNav'}>
            <ul>
              <li>
                <Link href={'/discover-mallorca'} as={'/discover-mallorca'}>
                  Discover Mallorca
                </Link>
              </li>
              <li>
                <Link href={'/concierge'} as={'/concierge'}>
                  Concierge
                </Link>
              </li>
              <li>
                <Link href={'/about-us'} as={'/about-us'}>
                  {t('about-us')}
                </Link>
              </li>
              <li>
                <Link href={'/contact-us'} as={'/contact-us'}>
                  {t('contact-us')}
                </Link>
              </li>
              <li>
                <FontAwesomeIcon icon={faHeart} />
                <Link href={'/account/wishlist'} as={'/account/wishlist'}>
                  {t('favourites')}
                </Link>
              </li>
              <li className={'login'}>
                <LoginMenu />
              </li>
              <Cart />
            </ul>
          </nav>
        </div>
        <div className={'mainMenu'}>
          <nav className={'menu'}>
            {navigation.length > 0 && (
              <ul>
                {navigation
                  .filter((menu) => {
                    return menu.Location === 'Header'
                  })[0]
                  .menuItems.map((item, i) => {
                    return (
                      <li
                        key={i}
                        className={`nav-item ${
                          '/' + props.active === item.url ? 'active' : ''
                        }`}
                      >
                        {item.subMenu.length > 0 && (
                          <SubMenu
                            title={item.title}
                            menuItems={item.subMenu}
                          />
                        )}
                        {item.subMenu.length === 0 && (
                          <Link
                            href={item.url}
                            as={item.url}
                            locale={router.locale}
                          >
                            {item.title}
                          </Link>
                        )}
                      </li>
                    )
                  })}
              </ul>
            )}
          </nav>
        </div>
      </div>
    )
  }

  return (
    <header className={'header'}>
      <div className={'logoWrapper'}>
        <Link href={'/'}>
          <a>
            <Logo />
          </a>
        </Link>
      </div>
      {mobile && <LanguageSelect />}
      {mobile && (
        <div className={'toggleNav'}>
          <button
            className={`hamburger ${menuOpen ? 'hamburgerOpen' : ''}`}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <span />
            <span />
            <span />
          </button>
          {menuOpen && (
            <Popup onClose={() => setMenuOpen(false)} style={{ right: '5vw' }}>
              <MainNav />
            </Popup>
          )}
        </div>
      )}
      {!mobile && <MainNav />}
    </header>
  )
}
Header.propTypes = {
  active: PropTypes.string,
}
export default Header
