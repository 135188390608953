import PropTypes from 'prop-types'

export default function Column({ className, grow = false, style, children }) {
  return (
    <div
      className={`column${grow === true ? ' grow' : ''} ${
        typeof className !== 'undefined' ? className : ''
      }`}
      style={style}
    >
      {children}
    </div>
  )
}
Column.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  grow: PropTypes.bool,
  style: PropTypes.object,
}
