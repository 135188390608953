import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingBag } from '@fortawesome/pro-regular-svg-icons'
import Popup from '../../popup'
import CheckoutPopup from '../../popups/checkout'
import React, { useContext, useEffect, useState } from 'react'
import styles from './header.module.scss'
import { useTranslation } from 'next-i18next'
import { CartContext } from '../../../lib/context/cart-context'
import { useCookies } from 'react-cookie'
import { UserContext } from '../../../lib/context/UserContext'
import { useMutation } from '@apollo/client'
import { ADD_CART, UPDATE_CART } from '../../../lib/queries'
import config from '../../../config'
import ApiContext from '../../../utils/api-context'

const Cart = () => {
  const { t, i18n } = useTranslation()
  const {
    itemCount,
    cartItems,
    id,
    addId,
    updateCart,
    showBasket,
    hideBasket,
  } = useContext(CartContext)
  const [checkoutOpen, setCheckoutOpen] = useState(false)
  const { apiUrl, cdnUrl } = useContext(ApiContext)
  const { user } = useContext(UserContext)
  const [cookies, setCookie] = useCookies(['jwt'])
  useEffect(() => {
    if (showBasket && cartItems.length > 0) {
      hideBasket()
      setCheckoutOpen(true)
    } else {
      hideBasket()
      setCheckoutOpen(false)
    }
  }, [itemCount])
  return (
    <li
      className={itemCount > 0 ? 'basket badge' : 'basket'}
      onClick={() => {
        if (itemCount > 0) {
          setCheckoutOpen(true)
        }
      }}
      data-count={itemCount}
    >
      <FontAwesomeIcon icon={faShoppingBag} />
      <span>{t('basket')}</span>
      {checkoutOpen && (
        <Popup
          header="My basket"
          onClose={() => setCheckoutOpen(false)}
          style={{ width: '450px', maxWidth: '90vw', right: '-20px' }}
        >
          <CheckoutPopup onClose={() => setCheckoutOpen(false)} />
        </Popup>
      )}
    </li>
  )
}
export default Cart
