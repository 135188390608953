import React, { useContext, useEffect, useState } from 'react'
import styles from './language-select.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faGlobe } from '@fortawesome/pro-regular-svg-icons'
import Popup from './popup'
import Button from './button'
import PropTypes, { symbol } from 'prop-types'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { CurrencyContext } from '../lib/context/CurrencyContext'
import config from '../config'
import { SettingsContext } from '../lib/context/SettingsContext'

const languages = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'es',
    name: 'Espanol',
  },
  {
    code: 'de',
    name: 'Deutsch',
  },
  {
    code: 'fr',
    name: 'Francais',
  },
]
const currencies = {
  GBP: '£',
  EUR: '€',
}

const LanguageSelect = (props) => {
  const [mobile, setMobile] = useState(true)
  const { alt = false } = props
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const [openLanguage, setOpenLanguage] = useState(false)
  const [chosenLanguage, setChosenLanguage] = useState(router.locale)
  const { currency, setCurrentCurrency } = useContext(CurrencyContext)
  const { settings } = useContext(SettingsContext)

  useEffect(() => {
    const isMobile = () => {
      setMobile(window.outerWidth <= 767)
    }
    if (window) {
      isMobile()
      window.addEventListener('resize', isMobile)
    }
    return () => {
      window.removeEventListener('resize', isMobile)
    }
  }, [])

  // const currency =
  //   typeof localStorage !== 'undefined' &&
  //   localStorage.getItem('currency') !== null
  //     ? localStorage.getItem('currency')
  //     : 'GBP'
  let newLanguage = router.locale
  let newCurrency = currency

  const toggleLanguage = () => {
    setOpenLanguage(!openLanguage)
  }
  const changeLanguage = () => {
    setCurrentCurrency(newCurrency, 0, config.cookieOptions)
    if (newLanguage !== router.locale) {
      i18n.changeLanguage(newLanguage).then(() => {
        router.push(router.asPath, router.asPath, { locale: newLanguage })
      })
    }
    setOpenLanguage(!openLanguage)
  }
  return (
    <div
      className={`${styles.languageSelection} ${
        alt === true ? styles.headerAlt : ''
      }`}
    >
      <div className={styles.languageSelectionInner}>
        <FontAwesomeIcon icon={faGlobe} />
        <div className={styles.currency}>
          {router.locale.toUpperCase()} / {currencies[currency]}
        </div>
        <FontAwesomeIcon
          icon={faChevronDown}
          className={styles.chevron}
          onClick={toggleLanguage}
        />
      </div>
      {openLanguage && (
        <Popup
          onClose={() => setOpenLanguage(false)}
          style={{
            left: !mobile ? '105px' : '50%',
            transform: 'translateX(-50%)',
            width: 285,
          }}
          position={'center'}
        >
          {process.env.NEXT_PUBLIC_SHOW_LANGUAGE === 'true' && (
            <div className={styles.languageSelect}>
              <h4>Language</h4>
              <select
                onChange={(evt) => {
                  newLanguage = evt.target.value
                }}
                defaultValue={router.locale}
              >
                {languages.map((option, i) => {
                  return (
                    <option value={option.code} key={i}>
                      {option.name}
                    </option>
                  )
                })}
              </select>
            </div>
          )}
          <div className={styles.languageSelect}>
            <h4>Currency</h4>
            <select
              onChange={(evt) => {
                newCurrency = evt.target.value
                setCurrentCurrency(newCurrency, 0, config.cookieOptions)
              }}
              defaultValue={currency}
            >
              {settings.exchangeRate.map((option, i) => {
                return (
                  <option value={option.currency} key={i}>
                    {option.symbol}
                  </option>
                )
              })}
            </select>
            <hr />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              width={'100%'}
              color={'green'}
              onClick={() => {
                changeLanguage()
              }}
            >
              <span>{t('save')}</span>
            </Button>
          </div>
        </Popup>
      )}
    </div>
  )
}
LanguageSelect.propTypes = {
  alt: PropTypes.bool,
}
export default LanguageSelect
